.section-auth {
    display: flex;
    background-image:linear-gradient(#1C2167, #2B468D, #3964B3);
    height:100vh;
    overflow-y: hidden;
    position: relative;
    flex-wrap: nowrap;
    flex-grow: initial;
}

.section-logo {
    background-color: transparent;
    flex:0.3;
}


.section-logo img {
    width: 100px;
}

.section-forms {
    padding-top:20vh;
    padding-left: 10vw;
    position: absolute;
    z-index: 0;
    right: 0px;
    top:-20vh;
    width: 60vw;
    background-color: #ddd;
    border-radius: 80% 0% 0 80%;
    flex:1;
    height: 140vh;
    color: #2B468D;
}

.login-btn {
    position: relative;
    font-weight: 600;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-btn > button {
    width: 60%;
    box-shadow: 0px 0px 1rem #2B468D !important;
    margin-top:1rem;
}

.divider {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin: 1rem 0px;
}

.divider span {
    margin:0 0.5rem;
}

.divider hr {
    flex:1;
    margin:1rem 0.5rem;
}

/* forms */
.section-forms .header-text {
    text-align: center;
    color:#2B468D;
    font-weight: 600;
    font-size: 1.5rem;
    margin: 10vh 0 5vh;
}

.section-forms .text {
    color:#2B468D;
}

.section-forms .view-img {
    position: absolute;
    left: -30%;
    top:28%;
}

.view-img img {
    width: 40%;
}

.input-group {
    display: flex;
}

.flex-group {
    display:flex;
    justify-content: space-between;
    margin:1rem 0;
}

.flex-group input {
    border-radius: 5px;
    border:1px solid #ddd;
    line-height: 1.8;
    height: 35px;
    padding:0 0.5rem;
    width: 100%;
}

.flex-group button {
    background-color: #4374C3;
    border-radius: 5px;
    border: 1px solid #4374C3;
    width: 100px;
    cursor: pointer;
    color:white;
}

button.btn-outline {
    border:none;
    background-color: transparent;
    color:#4374C3;
}

button.btn-outline:hover {
    background-color: #4374C3;
    color:white;
}

.form-section {
    padding:0.5rem 2.5rem;
}

.flex-group .input-group {
    display: flex;
    align-items: center;
}

.flex-group .input-group {
    background-color: white;
    flex: 0.8;
    border-radius: 5px;
    border: 1px solid #4374C3;
}

.flex-group .input-group.input-form input {
    border:none !important;
}

.input-group input {
    border: none;
    border-left: 2px solid #4374C3;
    border-radius: 0px;
    width: auto !important;
}

.input-group span {
    padding:0 0.5rem;
}


.submit-section {
    display: flex;
    justify-content: center;
    align-items: center;
}

.submit-section .btn-submit {
    background-color: #4374C3;
    color:white;
    border-radius: 20px;
    border:none;
    /* height: 32px; */
    width: 80%;
    padding:0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    font-size: 1rem;
}