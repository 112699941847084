*/.ct-golden-section:before,
.ct-major-eleventh:before,
.ct-major-second:before,
.ct-major-seventh:before,
.ct-major-sixth:before,
.ct-major-tenth:before,
.ct-major-third:before,
.ct-major-twelfth:before,
.ct-minor-second:before,
.ct-minor-seventh:before,
.ct-minor-third:before,
.ct-octave:before,
.ct-perfect-fifth:before,
.ct-perfect-fourth:before,
.ct-square:before {
	height: 0;
	content: '';
	float: left;
}
.ct-golden-section:after,
.ct-golden-section:before,
.ct-major-eleventh:after,
.ct-major-eleventh:before,
.ct-major-second:after,
.ct-major-second:before,
.ct-major-seventh:after,
.ct-major-seventh:before,
.ct-major-sixth:after,
.ct-major-sixth:before,
.ct-major-tenth:after,
.ct-major-tenth:before,
.ct-major-third:after,
.ct-major-third:before,
.ct-major-twelfth:after,
.ct-major-twelfth:before,
.ct-minor-second:after,
.ct-minor-second:before,
.ct-minor-seventh:after,
.ct-minor-seventh:before,
.ct-minor-sixth:before,
.ct-minor-third:after,
.ct-minor-third:before,
.ct-octave:after,
.ct-octave:before,
.ct-perfect-fifth:after,
.ct-perfect-fifth:before,
.ct-perfect-fourth:after,
.ct-perfect-fourth:before,
.ct-square:after,
.ct-square:before {
	content: '';
}
.chartist-tooltip,
.ct-area,
.ct-line,
.ui-state-disabled {
	pointer-events: none;
}
.sidebar .user .info .case,
.sidebar .user .info .case,
.ui-helper-reset {
	text-decoration: none;
}
.mapael .zoomButton,
.toggle-group {
	-webkit-user-select: none;
	-moz-user-select: none;
}
.bg-default {
	background: #607d8b !important;
}
.bg-primary {
	background: #1d62f0 !important;
}
.bg-info {
	background: #23ccef !important;
}
.bg-success {
	background: #59d05d !important;
}
.bg-warning {
	background: #fbad4c !important;
}
.bg-danger {
	background: #ff646d !important;
}
:focus {
	outline: 0 !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}
.alert,
.brand,
.btn-simple,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.navbar,
.td-name,
a,
body,
button.close,
h1,
h2,
h3,
h4,
h5,
h6,
p,
td {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: nunito, sans-serif;
}
body {
	font-size: 14px;
	color: #575962;
}
.h1,
h1 {
	font-size: 59px;
}
.h2,
h2 {
	font-size: 43px;
}
.h3,
h3 {
	font-size: 35px;
	margin: 20px 0 10px;
}
.h4,
h4 {
	font-size: 28px;
	line-height: 30px;
}
.h5,
h5 {
	font-size: 25px;
	margin-bottom: 15px;
}
.h6,
h6 {
	font-size: 18px;
	font-weight: 600;
}
p {
	font-size: 15px;
	line-height: 1.5;
	margin-bottom: 0.7rem;
}
h4.page-title {
	font-size: 22px;
	font-weight: 600;
	color: #333;
	line-height: 30px;
	margin-bottom: 20px;
}
.text-primary,
.text-primary:hover {
	color: #1d62f0 !important;
}
.text-info,
.text-info:hover {
	color: #23ccef !important;
}
.text-success,
.text-success:hover {
	color: #59d05d !important;
}
.text-warning,
.text-warning:hover {
	color: #fbad4c !important;
}
.text-danger,
.text-danger:hover {
	color: #ff646d !important;
}
label {
	color: #3f4047 !important;
	font-size: 14px !important;
}
.fw-light {
	font-weight: lighter !important;
}
.fw-normal {
	font-weight: 400 !important;
}
.fw-mediumbold {
	font-weight: 500 !important;
}
.fw-bold {
	font-weight: 600 !important;
}
.fw-extrabold {
	font-weight: 800 !important;
}
.ct-label {
	fill: rgba(0, 0, 0, 0.5);
	color: rgba(0, 0, 0, 0.5);
	font-size: 0.8rem;
	line-height: 1;
}
.ct-grid-background,
.ct-line {
	fill: none;
}
.ct-chart-bar .ct-label,
.ct-chart-line .ct-label {
	display: block;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}
.ct-label.ct-horizontal.ct-start {
	-webkit-box-align: flex-end;
	-webkit-align-items: flex-end;
	-ms-flex-align: flex-end;
	align-items: flex-end;
	-webkit-box-pack: flex-start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: flex-start;
	justify-content: flex-start;
	text-align: left;
	text-anchor: start;
}
.ct-label.ct-horizontal.ct-end {
	-webkit-box-align: flex-start;
	-webkit-align-items: flex-start;
	-ms-flex-align: flex-start;
	align-items: flex-start;
	-webkit-box-pack: flex-start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: flex-start;
	justify-content: flex-start;
	text-align: left;
	text-anchor: start;
}
.ct-label.ct-vertical.ct-start {
	-webkit-box-align: flex-end;
	-webkit-align-items: flex-end;
	-ms-flex-align: flex-end;
	align-items: flex-end;
	-webkit-box-pack: flex-end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: flex-end;
	justify-content: flex-end;
	text-align: right;
	text-anchor: end;
}
.ct-label.ct-vertical.ct-end {
	-webkit-box-align: flex-end;
	-webkit-align-items: flex-end;
	-ms-flex-align: flex-end;
	align-items: flex-end;
	-webkit-box-pack: flex-start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: flex-start;
	justify-content: flex-start;
	text-align: left;
	text-anchor: start;
}
.ct-chart-bar .ct-label.ct-horizontal.ct-start {
	-webkit-box-align: flex-end;
	-webkit-align-items: flex-end;
	-ms-flex-align: flex-end;
	align-items: flex-end;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	text-align: center;
	text-anchor: start;
}
.ct-chart-bar .ct-label.ct-horizontal.ct-end {
	-webkit-box-align: flex-start;
	-webkit-align-items: flex-start;
	-ms-flex-align: flex-start;
	align-items: flex-start;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	text-align: center;
	text-anchor: start;
}
.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-start {
	-webkit-box-align: flex-end;
	-webkit-align-items: flex-end;
	-ms-flex-align: flex-end;
	align-items: flex-end;
	-webkit-box-pack: flex-start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: flex-start;
	justify-content: flex-start;
	text-align: left;
	text-anchor: start;
}
.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-end {
	-webkit-box-align: flex-start;
	-webkit-align-items: flex-start;
	-ms-flex-align: flex-start;
	align-items: flex-start;
	-webkit-box-pack: flex-start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: flex-start;
	justify-content: flex-start;
	text-align: left;
	text-anchor: start;
}
.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-start {
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: flex-end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: flex-end;
	justify-content: flex-end;
	text-align: right;
	text-anchor: end;
}
.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-end {
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: flex-start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: flex-start;
	justify-content: flex-start;
	text-align: left;
	text-anchor: end;
}
.ct-grid {
	stroke: rgba(0, 0, 0, 0.2);
	stroke-width: 1px;
	stroke-dasharray: 2px;
}
.ct-point {
	stroke-width: 7px;
	stroke-linecap: round;
}
.ct-line {
	stroke-width: 3px;
}
.ct-area {
	stroke: none;
	fill-opacity: 0.1;
}
.ct-bar {
	fill: none;
	stroke-width: 10px;
}
.ct-slice-donut {
	fill: none;
	stroke-width: 60px;
}
.ct-series-a .ct-bar,
.ct-series-a .ct-line,
.ct-series-a .ct-point,
.ct-series-a .ct-slice-donut {
	stroke: #1d62f0;
}
.ct-series-a .ct-area,
.ct-series-a .ct-slice-donut-solid,
.ct-series-a .ct-slice-pie {
	fill: #1d62f0;
}
.ct-series-b .ct-bar,
.ct-series-b .ct-line,
.ct-series-b .ct-point,
.ct-series-b .ct-slice-donut {
	stroke: #ff646d;
}
.ct-series-b .ct-area,
.ct-series-b .ct-slice-donut-solid,
.ct-series-b .ct-slice-pie {
	fill: #ff646d;
}
.ct-series-c .ct-bar,
.ct-series-c .ct-line,
.ct-series-c .ct-point,
.ct-series-c .ct-slice-donut {
	stroke: #fbad4c;
}
.ct-series-c .ct-area,
.ct-series-c .ct-slice-donut-solid,
.ct-series-c .ct-slice-pie {
	fill: #fbad4c;
}
.ct-series-d .ct-bar,
.ct-series-d .ct-line,
.ct-series-d .ct-point,
.ct-series-d .ct-slice-donut {
	stroke: #9368e9;
}
.ct-series-d .ct-area,
.ct-series-d .ct-slice-donut-solid,
.ct-series-d .ct-slice-pie {
	fill: #9368e9;
}
.ct-series-e .ct-bar,
.ct-series-e .ct-line,
.ct-series-e .ct-point,
.ct-series-e .ct-slice-donut {
	stroke: #87cb16;
}
.ct-series-e .ct-area,
.ct-series-e .ct-slice-donut-solid,
.ct-series-e .ct-slice-pie {
	fill: #87cb16;
}
.ct-series-f .ct-bar,
.ct-series-f .ct-line,
.ct-series-f .ct-point,
.ct-series-f .ct-slice-donut {
	stroke: #59922b;
}
.ct-series-f .ct-area,
.ct-series-f .ct-slice-donut-solid,
.ct-series-f .ct-slice-pie {
	fill: #59922b;
}
.ct-series-g .ct-bar,
.ct-series-g .ct-line,
.ct-series-g .ct-point,
.ct-series-g .ct-slice-donut {
	stroke: #0544d3;
}
.ct-series-g .ct-area,
.ct-series-g .ct-slice-donut-solid,
.ct-series-g .ct-slice-pie {
	fill: #0544d3;
}
.ct-series-h .ct-bar,
.ct-series-h .ct-line,
.ct-series-h .ct-point,
.ct-series-h .ct-slice-donut {
	stroke: #6b0392;
}
.ct-series-h .ct-area,
.ct-series-h .ct-slice-donut-solid,
.ct-series-h .ct-slice-pie {
	fill: #6b0392;
}
.ct-series-i .ct-bar,
.ct-series-i .ct-line,
.ct-series-i .ct-point,
.ct-series-i .ct-slice-donut {
	stroke: #f05b4f;
}
.ct-series-i .ct-area,
.ct-series-i .ct-slice-donut-solid,
.ct-series-i .ct-slice-pie {
	fill: #f05b4f;
}
.ct-series-j .ct-bar,
.ct-series-j .ct-line,
.ct-series-j .ct-point,
.ct-series-j .ct-slice-donut {
	stroke: #dda458;
}
.ct-series-j .ct-area,
.ct-series-j .ct-slice-donut-solid,
.ct-series-j .ct-slice-pie {
	fill: #dda458;
}
.ct-series-k .ct-bar,
.ct-series-k .ct-line,
.ct-series-k .ct-point,
.ct-series-k .ct-slice-donut {
	stroke: #eacf7d;
}
.ct-series-k .ct-area,
.ct-series-k .ct-slice-donut-solid,
.ct-series-k .ct-slice-pie {
	fill: #eacf7d;
}
.ct-series-l .ct-bar,
.ct-series-l .ct-line,
.ct-series-l .ct-point,
.ct-series-l .ct-slice-donut {
	stroke: #86797d;
}
.ct-series-l .ct-area,
.ct-series-l .ct-slice-donut-solid,
.ct-series-l .ct-slice-pie {
	fill: #86797d;
}
.ct-series-m .ct-bar,
.ct-series-m .ct-line,
.ct-series-m .ct-point,
.ct-series-m .ct-slice-donut {
	stroke: #b2c326;
}
.ct-series-m .ct-area,
.ct-series-m .ct-slice-donut-solid,
.ct-series-m .ct-slice-pie {
	fill: #b2c326;
}
.ct-series-n .ct-bar,
.ct-series-n .ct-line,
.ct-series-n .ct-point,
.ct-series-n .ct-slice-donut {
	stroke: #6188e2;
}
.ct-series-n .ct-area,
.ct-series-n .ct-slice-donut-solid,
.ct-series-n .ct-slice-pie {
	fill: #6188e2;
}
.ct-series-o .ct-bar,
.ct-series-o .ct-line,
.ct-series-o .ct-point,
.ct-series-o .ct-slice-donut {
	stroke: #a748ca;
}
.ct-series-o .ct-area,
.ct-series-o .ct-slice-donut-solid,
.ct-series-o .ct-slice-pie {
	fill: #a748ca;
}
.ct-square {
	display: block;
	position: relative;
	width: 100%;
}
.ct-square:before {
	display: block;
	width: 0;
	padding-bottom: 100%;
}
.ct-square:after {
	display: table;
	clear: both;
}
.ct-square > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}
.ct-minor-second {
	display: block;
	position: relative;
	width: 100%;
}
.ct-minor-second:before {
	display: block;
	width: 0;
	padding-bottom: 93.75%;
}
.ct-minor-second:after {
	display: table;
	clear: both;
}
.ct-minor-second > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}
.ct-major-second {
	display: block;
	position: relative;
	width: 100%;
}
.ct-major-second:before {
	display: block;
	width: 0;
	padding-bottom: 88.8888888889%;
}
.ct-major-second:after {
	display: table;
	clear: both;
}
.ct-major-second > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}
.ct-minor-third {
	display: block;
	position: relative;
	width: 100%;
}
.ct-minor-third:before {
	display: block;
	width: 0;
	padding-bottom: 83.3333333333%;
}
.ct-minor-third:after {
	display: table;
	clear: both;
}
.ct-minor-third > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}
.ct-major-third {
	display: block;
	position: relative;
	width: 100%;
}
.ct-major-third:before {
	display: block;
	width: 0;
	padding-bottom: 80%;
}
.ct-major-third:after {
	display: table;
	clear: both;
}
.ct-major-third > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}
.ct-perfect-fourth {
	display: block;
	position: relative;
	width: 100%;
}
.ct-perfect-fourth:before {
	display: block;
	width: 0;
	padding-bottom: 75%;
}
.ct-perfect-fourth:after {
	display: table;
	clear: both;
}
.ct-perfect-fourth > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}
.ct-perfect-fifth {
	display: block;
	position: relative;
	width: 100%;
}
.ct-perfect-fifth:before {
	display: block;
	width: 0;
	padding-bottom: 66.6666666667%;
}
.ct-perfect-fifth:after {
	display: table;
	clear: both;
}
.ct-perfect-fifth > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}
.ct-minor-sixth {
	display: block;
	position: relative;
	width: 100%;
}
.ct-minor-sixth:before {
	display: block;
	float: left;
	width: 0;
	height: 0;
	padding-bottom: 62.5%;
}
.ct-minor-sixth:after {
	content: '';
	display: table;
	clear: both;
}
.ct-minor-sixth > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}
.ct-golden-section {
	display: block;
	position: relative;
	width: 100%;
}
.ct-golden-section:before {
	display: block;
	width: 0;
	padding-bottom: 61.804697157%;
}
.ct-golden-section:after {
	display: table;
	clear: both;
}
.ct-golden-section > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}
.ct-major-sixth {
	display: block;
	position: relative;
	width: 100%;
}
.ct-major-sixth:before {
	display: block;
	width: 0;
	padding-bottom: 60%;
}
.ct-major-sixth:after {
	display: table;
	clear: both;
}
.ct-major-sixth > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}
.ct-minor-seventh {
	display: block;
	position: relative;
	width: 100%;
}
.ct-minor-seventh:before {
	display: block;
	width: 0;
	padding-bottom: 56.25%;
}
.ct-minor-seventh:after {
	display: table;
	clear: both;
}
.ct-minor-seventh > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}
.ct-major-seventh {
	display: block;
	position: relative;
	width: 100%;
}
.ct-major-seventh:before {
	display: block;
	width: 0;
	padding-bottom: 53.3333333333%;
}
.ct-major-seventh:after {
	display: table;
	clear: both;
}
.ct-major-seventh > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}
.ct-octave {
	display: block;
	position: relative;
	width: 100%;
}
.ct-octave:before {
	display: block;
	width: 0;
	padding-bottom: 50%;
}
.ct-octave:after {
	display: table;
	clear: both;
}
.ct-octave > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}
.ct-major-tenth {
	display: block;
	position: relative;
	width: 100%;
}
.ct-major-tenth:before {
	display: block;
	width: 0;
	padding-bottom: 40%;
}
.ct-major-tenth:after {
	display: table;
	clear: both;
}
.ct-major-tenth > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}
.ct-major-eleventh {
	display: block;
	position: relative;
	width: 100%;
}
.ct-major-eleventh:before {
	display: block;
	width: 0;
	padding-bottom: 37.5%;
}
.ct-major-eleventh:after {
	display: table;
	clear: both;
}
.ct-major-eleventh > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}
.ct-major-twelfth {
	display: block;
	position: relative;
	width: 100%;
}
.ct-major-twelfth:before {
	display: block;
	width: 0;
	padding-bottom: 33.3333333333%;
}
.ct-major-twelfth:after {
	display: table;
	clear: both;
}
.ct-major-twelfth > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}
.ct-double-octave {
	display: block;
	position: relative;
	width: 100%;
}
.chartist-tooltip:before,
.ct-double-octave:before {
	width: 0;
	height: 0;
	content: '';
}
.ct-double-octave:before {
	display: block;
	float: left;
	padding-bottom: 25%;
}
.ct-double-octave:after {
	content: '';
	display: table;
	clear: both;
}
.ct-double-octave > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}
.chartist-tooltip {
	position: absolute;
	display: inline-block;
	opacity: 0;
	min-width: 3em;
	padding: 0.25em;
	background: rgba(0, 0, 0, 0.77);
	color: #fff;
	font-weight: 600;
	text-align: center;
	z-index: 1;
	-webkit-transition: opacity 0.2s linear;
	-moz-transition: opacity 0.2s linear;
	-o-transition: opacity 0.2s linear;
	transition: opacity 0.2s linear;
}
.chartist-tooltip:before {
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border: 5px solid transparent;
	border-top-color: rgba(0, 0, 0, 0.77);
}
.chartist-tooltip-value {
	font-size: 14px;
}
.chartist-tooltip.tooltip-show {
	opacity: 1;
}
.ui-draggable-handle {
	-ms-touch-action: none;
	touch-action: none;
}
.ui-helper-hidden {
	display: none;
}
.ui-helper-hidden-accessible {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
.ui-helper-reset {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	line-height: 1.3;
	font-size: 100%;
	list-style: none;
}
.ui-helper-clearfix:after,
.ui-helper-clearfix:before {
	content: '';
	display: table;
	border-collapse: collapse;
}
.ui-helper-clearfix:after {
	clear: both;
}
.ui-helper-zfix {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	opacity: 0;
	filter: Alpha(Opacity=0);
}
.ui-front {
	z-index: 100;
}
.ui-state-disabled {
	cursor: default !important;
}
.ui-icon {
	display: inline-block;
	vertical-align: middle;
	margin-top: -0.25em;
	position: relative;
	text-indent: -99999px;
	overflow: hidden;
	background-repeat: no-repeat;
}
.ui-widget-icon-block {
	left: 50%;
	margin-left: -8px;
	display: block;
}
.ui-widget-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.ui-resizable {
	position: relative;
}
.ui-resizable-handle {
	position: absolute;
	font-size: 0.1px;
	display: block;
	-ms-touch-action: none;
	touch-action: none;
}
.ui-resizable-autohide .ui-resizable-handle,
.ui-resizable-disabled .ui-resizable-handle {
	display: none;
}
.ui-resizable-n {
	cursor: n-resize;
	height: 7px;
	width: 100%;
	top: -5px;
	left: 0;
}
.ui-resizable-s {
	cursor: s-resize;
	height: 7px;
	width: 100%;
	bottom: -5px;
	left: 0;
}
.ui-resizable-e {
	cursor: e-resize;
	width: 7px;
	right: -5px;
	top: 0;
	height: 100%;
}
.ui-resizable-w {
	cursor: w-resize;
	width: 7px;
	left: -5px;
	top: 0;
	height: 100%;
}
.ui-resizable-se {
	cursor: se-resize;
	width: 12px;
	height: 12px;
	right: 1px;
	bottom: 1px;
}
.ui-resizable-sw {
	cursor: sw-resize;
	width: 9px;
	height: 9px;
	left: -5px;
	bottom: -5px;
}
.ui-resizable-nw {
	cursor: nw-resize;
	width: 9px;
	height: 9px;
	left: -5px;
	top: -5px;
}
.ui-resizable-ne {
	cursor: ne-resize;
	width: 9px;
	height: 9px;
	right: -5px;
	top: -5px;
}
.ui-selectable {
	-ms-touch-action: none;
	touch-action: none;
}
.ui-selectable-helper {
	position: absolute;
	z-index: 100;
	border: 1px dotted #000;
}
.ui-sortable-handle {
	-ms-touch-action: none;
	touch-action: none;
}
.ui-slider {
	position: relative;
	text-align: left;
	background: #ddd;
}
.ui-slider .ui-slider-handle {
	position: absolute;
	z-index: 2;
	width: 1em;
	height: 1em;
	cursor: default;
	-ms-touch-action: none;
	touch-action: none;
}
.ui-slider .ui-slider-range {
	position: absolute;
	z-index: 1;
	font-size: 0.7em;
	display: block;
	border: 0;
	background-position: 0 0;
}
.ui-slider.ui-state-disabled .ui-slider-handle,
.ui-slider.ui-state-disabled .ui-slider-range {
	filter: inherit;
}
.ui-slider-horizontal {
	height: 0.4em;
}
.ui-slider-horizontal .ui-slider-handle {
	top: -0.4em;
	margin-left: -0.6em;
}
.ui-slider-horizontal .ui-slider-range {
	top: 0;
	height: 100%;
}
.ui-slider-horizontal .ui-slider-range-min {
	left: 0;
}
.ui-slider-horizontal .ui-slider-range-max {
	right: 0;
}
.ui-slider-vertical {
	width: 0.8em;
	height: 100px;
}
.ui-slider-vertical .ui-slider-handle {
	left: -0.3em;
	margin-left: 0;
	margin-bottom: -0.6em;
}
.ui-slider-vertical .ui-slider-range {
	left: 0;
	width: 100%;
}
.ui-slider-vertical .ui-slider-range-min {
	bottom: 0;
}
.ui-slider-vertical .ui-slider-range-max {
	top: 0;
}
.ui-slider-handle,
.ui-slider-handle:focus {
	background: #fff;
	background: -moz-linear-gradient(top, #fff 0, #f7f7f7 100%);
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(0, #fff),
		color-stop(100%, #f7f7f7)
	);
	background: -webkit-linear-gradient(top, #fff 0, #f7f7f7 100%);
	background: -o-linear-gradient(top, #fff 0, #f7f7f7 100%);
	background: -ms-linear-gradient(top, #fff 0, #f7f7f7 100%);
	background: linear-gradient(to bottom, #fff 0, #f7f7f7 100%);
	border-radius: 50px;
	box-shadow: 0 1px 4px 0 #9191ab !important;
}
.scroll-wrapper {
	overflow: hidden !important;
	padding: 0 !important;
	position: relative;
}
.scroll-wrapper > .scroll-content {
	border: none !important;
	box-sizing: content-box !important;
	height: auto;
	left: 0;
	margin: 0;
	max-height: none;
	max-width: none !important;
	overflow: scroll !important;
	padding: 0;
	position: relative !important;
	top: 0;
	width: auto !important;
}
.scroll-element,
.scroll-element div {
	box-sizing: content-box;
}
.scroll-wrapper > .scroll-content::-webkit-scrollbar {
	height: 0;
	width: 0;
}
.scroll-element {
	display: none;
}
.scroll-element.scroll-x.scroll-scrollx_visible,
.scroll-element.scroll-y.scroll-scrolly_visible {
	display: block;
}
.scroll-element .scroll-arrow,
.scroll-element .scroll-bar {
	cursor: default;
}
.scroll-textarea {
	border: 1px solid #ccc;
	border-top-color: #999;
}
.scroll-textarea > .scroll-content {
	overflow: hidden !important;
}
.scroll-textarea > .scroll-content > textarea {
	border: none !important;
	box-sizing: border-box;
	height: 100% !important;
	margin: 0;
	max-height: none !important;
	max-width: none !important;
	overflow: scroll !important;
	outline: 0;
	padding: 2px;
	position: relative !important;
	top: 0;
	width: 100% !important;
}
.scrollbar-inner > .scroll-element,
.scrollbar-inner > .scroll-element div,
.scrollbar-outer > .scroll-element,
.scrollbar-outer > .scroll-element div {
	margin: 0;
	padding: 0;
	position: absolute;
	z-index: 10;
}
.scrollbar-inner > .scroll-element .scroll-element_outer,
.scrollbar-outer > .scroll-element .scroll-element_outer {
	overflow: hidden;
}
.scroll-textarea > .scroll-content > textarea::-webkit-scrollbar {
	height: 0;
	width: 0;
}
.scrollbar-inner > .scroll-element {
	border: none;
}
.scrollbar-inner > .scroll-element div {
	border: none;
	display: block;
	height: 100%;
	left: 0;
	top: 0;
	width: 100%;
}
.scrollbar-inner > .scroll-element.scroll-x {
	bottom: 2px;
	height: 8px;
	left: 0;
	width: 100%;
}
.scrollbar-inner > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size,
.scrollbar-inner > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track {
	left: -12px;
}
.scrollbar-inner > .scroll-element.scroll-y {
	height: 100%;
	right: 2px;
	top: 0;
	width: 8px;
}
.scrollbar-inner > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size,
.scrollbar-inner > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track {
	top: -12px;
}
.scrollbar-inner > .scroll-element .scroll-bar,
.scrollbar-inner > .scroll-element .scroll-element_outer,
.scrollbar-inner > .scroll-element .scroll-element_track {
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
}
.scrollbar-inner > .scroll-element .scroll-bar {
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=40)';
	filter: alpha(opacity=40);
	opacity: 0.4;
}
.scrollbar-inner > .scroll-element .scroll-element_track {
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=40)';
	filter: alpha(opacity=40);
	opacity: 0.4;
	background-color: #e0e0e0;
}
.scrollbar-inner > .scroll-element .scroll-bar {
	background-color: #c2c2c2;
}
.scrollbar-inner > .scroll-element.scroll-draggable .scroll-bar,
.scrollbar-inner > .scroll-element:hover .scroll-bar {
	background-color: #919191;
}
.scrollbar-outer > .scroll-element {
	border: none;
	background-color: #fff;
}
.scrollbar-outer > .scroll-element div {
	border: none;
	display: block;
	height: 100%;
	left: 0;
	top: 0;
	width: 100%;
}
.scrollbar-outer > .scroll-element.scroll-x {
	bottom: 0;
	height: 12px;
	left: 0;
	width: 100%;
}
.scrollbar-outer > .scroll-element.scroll-y {
	height: 100%;
	right: 0;
	top: 0;
	width: 12px;
}
.scrollbar-outer > .scroll-element.scroll-x .scroll-element_outer {
	height: 8px;
	top: 2px;
}
.scrollbar-outer > .scroll-element.scroll-y .scroll-element_outer {
	left: 2px;
	width: 8px;
}
.scrollbar-outer > .scroll-element .scroll-element_track {
	background-color: #eee;
}
.scrollbar-outer > .scroll-element .scroll-bar,
.scrollbar-outer > .scroll-element .scroll-element_outer,
.scrollbar-outer > .scroll-element .scroll-element_track {
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
}
.scrollbar-outer > .scroll-element .scroll-bar {
	background-color: #d9d9d9;
}
.scrollbar-outer > .scroll-element .scroll-bar:hover {
	background-color: #c2c2c2;
}
.scrollbar-outer > .scroll-element.scroll-draggable .scroll-bar {
	background-color: #919191;
}
.scrollbar-outer > .scroll-content.scroll-scrolly_visible {
	left: -12px;
	margin-left: 12px;
}
.scrollbar-outer > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size,
.scrollbar-outer > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track {
	left: -14px;
}
.scrollbar-outer > .scroll-content.scroll-scrollx_visible {
	top: -12px;
	margin-top: 12px;
}
.scrollbar-macosx > .scroll-element,
.scrollbar-macosx > .scroll-element div {
	background: 0 0;
	border: none;
	margin: 0;
	padding: 0;
	position: absolute;
	z-index: 10;
}
.scrollbar-outer > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size,
.scrollbar-outer > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track {
	top: -14px;
}
.scrollbar-outer > .scroll-element.scroll-x .scroll-bar {
	min-width: 10px;
}
.scrollbar-outer > .scroll-element.scroll-y .scroll-bar {
	min-height: 10px;
}
.scrollbar-macosx > .scroll-element div {
	display: block;
	height: 100%;
	left: 0;
	top: 0;
	width: 100%;
}
.scrollbar-macosx > .scroll-element .scroll-element_track {
	display: none;
}
.scrollbar-macosx > .scroll-element .scroll-bar {
	background-color: #6c6e71;
	display: block;
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
	filter: alpha(opacity=0);
	opacity: 0;
	-webkit-border-radius: 7px;
	-moz-border-radius: 7px;
	border-radius: 7px;
	-webkit-transition: opacity 0.2s linear;
	-moz-transition: opacity 0.2s linear;
	-o-transition: opacity 0.2s linear;
	-ms-transition: opacity 0.2s linear;
	transition: opacity 0.2s linear;
}
.scrollbar-macosx:hover > .scroll-element .scroll-bar,
.scrollbar-macosx > .scroll-element.scroll-draggable .scroll-bar {
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=70)';
	filter: alpha(opacity=70);
	opacity: 0.7;
}
.scrollbar-macosx > .scroll-element.scroll-x {
	bottom: 0;
	height: 0;
	left: 0;
	min-width: 100%;
	overflow: visible;
	width: 100%;
}
.scrollbar-light > .scroll-element,
.scrollbar-light > .scroll-element div {
	border: none;
	margin: 0;
	overflow: hidden;
	padding: 0;
	position: absolute;
	z-index: 10;
}
.scrollbar-macosx > .scroll-element.scroll-y {
	height: 100%;
	min-height: 100%;
	right: 0;
	top: 0;
	width: 0;
}
.scrollbar-macosx > .scroll-element.scroll-x .scroll-bar {
	height: 7px;
	min-width: 10px;
	top: -9px;
}
.scrollbar-macosx > .scroll-element.scroll-y .scroll-bar {
	left: -9px;
	min-height: 10px;
	width: 7px;
}
.scrollbar-macosx > .scroll-element.scroll-x .scroll-element_outer {
	left: 2px;
}
.scrollbar-macosx > .scroll-element.scroll-x .scroll-element_size {
	left: -4px;
}
.scrollbar-macosx > .scroll-element.scroll-y .scroll-element_outer {
	top: 2px;
}
.scrollbar-macosx > .scroll-element.scroll-y .scroll-element_size {
	top: -4px;
}
.scrollbar-macosx > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
	left: -11px;
}
.scrollbar-macosx > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
	top: -11px;
}
.scrollbar-light > .scroll-element {
	background-color: #fff;
}
.scrollbar-light > .scroll-element div {
	display: block;
	height: 100%;
	left: 0;
	top: 0;
	width: 100%;
}
.scrollbar-light > .scroll-element .scroll-element_outer {
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
}
.scrollbar-light > .scroll-element .scroll-element_size {
	background: -moz-linear-gradient(left, #dbdbdb 0, #e8e8e8 100%);
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(0, #dbdbdb),
		color-stop(100%, #e8e8e8)
	);
	background: -webkit-linear-gradient(left, #dbdbdb 0, #e8e8e8 100%);
	background: -o-linear-gradient(left, #dbdbdb 0, #e8e8e8 100%);
	background: -ms-linear-gradient(left, #dbdbdb 0, #e8e8e8 100%);
	background: linear-gradient(to right, #dbdbdb 0, #e8e8e8 100%);
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
}
.scrollbar-light > .scroll-element.scroll-x {
	bottom: 0;
	height: 17px;
	left: 0;
	min-width: 100%;
	width: 100%;
}
.scrollbar-light > .scroll-element.scroll-y {
	height: 100%;
	min-height: 100%;
	right: 0;
	top: 0;
	width: 17px;
}
.scrollbar-light > .scroll-element .scroll-bar {
	background: -moz-linear-gradient(left, #fefefe 0, #f5f5f5 100%);
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(0, #fefefe),
		color-stop(100%, #f5f5f5)
	);
	background: -webkit-linear-gradient(left, #fefefe 0, #f5f5f5 100%);
	background: -o-linear-gradient(left, #fefefe 0, #f5f5f5 100%);
	background: -ms-linear-gradient(left, #fefefe 0, #f5f5f5 100%);
	background: linear-gradient(to right, #fefefe 0, #f5f5f5 100%);
	border: 1px solid #dbdbdb;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
}
.scrollbar-light > .scroll-content.scroll-scrolly_visible {
	left: -17px;
	margin-left: 17px;
}
.scrollbar-light > .scroll-content.scroll-scrollx_visible {
	top: -17px;
	margin-top: 17px;
}
.scrollbar-rail > .scroll-element,
.scrollbar-rail > .scroll-element div {
	border: none;
	margin: 0;
	padding: 0;
	position: absolute;
	z-index: 10;
	overflow: hidden;
}
.scrollbar-light > .scroll-element.scroll-x .scroll-bar {
	height: 10px;
	min-width: 10px;
	top: 0;
}
.scrollbar-light > .scroll-element.scroll-y .scroll-bar {
	left: 0;
	min-height: 10px;
	width: 10px;
}
.scrollbar-light > .scroll-element.scroll-x .scroll-element_outer {
	height: 12px;
	left: 2px;
	top: 2px;
}
.scrollbar-light > .scroll-element.scroll-x .scroll-element_size {
	left: -4px;
}
.scrollbar-light > .scroll-element.scroll-y .scroll-element_outer {
	left: 2px;
	top: 2px;
	width: 12px;
}
.scrollbar-light > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size,
.scrollbar-light > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track {
	left: -19px;
}
.scrollbar-light > .scroll-element.scroll-y .scroll-element_size {
	top: -4px;
}
.scrollbar-light > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size,
.scrollbar-light > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track {
	top: -19px;
}
.scrollbar-rail > .scroll-element {
	background-color: #fff;
}
.scrollbar-rail > .scroll-element div {
	display: block;
	height: 100%;
	left: 0;
	top: 0;
	width: 100%;
}
.scrollbar-rail > .scroll-element .scroll-element_size {
	background-color: #999;
	background-color: rgba(0, 0, 0, 0.3);
}
.scrollbar-rail > .scroll-element .scroll-element_outer:hover .scroll-element_size {
	background-color: #666;
	background-color: rgba(0, 0, 0, 0.5);
}
.scrollbar-rail > .scroll-element.scroll-x {
	bottom: 0;
	height: 12px;
	left: 0;
	min-width: 100%;
	padding: 3px 0 2px;
	width: 100%;
}
.scrollbar-rail > .scroll-element.scroll-y {
	height: 100%;
	min-height: 100%;
	padding: 0 2px 0 3px;
	right: 0;
	top: 0;
	width: 12px;
}
.scrollbar-rail > .scroll-element .scroll-bar {
	background-color: #d0b9a0;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}
.scrollbar-rail > .scroll-element .scroll-element_outer:hover .scroll-bar {
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
}
.scrollbar-rail > .scroll-content.scroll-scrolly_visible {
	left: -17px;
	margin-left: 17px;
}
.scrollbar-rail > .scroll-content.scroll-scrollx_visible {
	margin-top: 17px;
	top: -17px;
}
.scrollbar-dynamic > .scroll-element,
.scrollbar-dynamic > .scroll-element div {
	background: 0 0;
	border: none;
	margin: 0;
	padding: 0;
	position: absolute;
	z-index: 10;
}
.scrollbar-rail > .scroll-element.scroll-x .scroll-bar {
	height: 10px;
	min-width: 10px;
	top: 1px;
}
.scrollbar-rail > .scroll-element.scroll-y .scroll-bar {
	left: 1px;
	min-height: 10px;
	width: 10px;
}
.scrollbar-rail > .scroll-element.scroll-x .scroll-element_outer {
	height: 15px;
	left: 5px;
}
.scrollbar-rail > .scroll-element.scroll-x .scroll-element_size {
	height: 2px;
	left: -10px;
	top: 5px;
}
.scrollbar-rail > .scroll-element.scroll-y .scroll-element_outer {
	top: 5px;
	width: 15px;
}
.scrollbar-rail > .scroll-element.scroll-y .scroll-element_size {
	left: 5px;
	top: -10px;
	width: 2px;
}
.scrollbar-rail > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size,
.scrollbar-rail > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track {
	left: -25px;
}
.scrollbar-rail > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size,
.scrollbar-rail > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track {
	top: -25px;
}
.scrollbar-dynamic > .scroll-element div {
	display: block;
	height: 100%;
	left: 0;
	top: 0;
	width: 100%;
}
.scrollbar-dynamic > .scroll-element.scroll-x {
	bottom: 2px;
	height: 7px;
	left: 0;
	min-width: 100%;
	width: 100%;
}
.scrollbar-dynamic > .scroll-element.scroll-y {
	height: 100%;
	min-height: 100%;
	right: 2px;
	top: 0;
	width: 7px;
}
.scrollbar-dynamic > .scroll-element .scroll-element_outer {
	opacity: 0.3;
	-webkit-border-radius: 12px;
	-moz-border-radius: 12px;
	border-radius: 12px;
}
.scrollbar-dynamic > .scroll-element .scroll-element_size {
	background-color: #ccc;
	opacity: 0;
	-webkit-border-radius: 12px;
	-moz-border-radius: 12px;
	border-radius: 12px;
	-webkit-transition: opacity 0.2s;
	-moz-transition: opacity 0.2s;
	-o-transition: opacity 0.2s;
	-ms-transition: opacity 0.2s;
	transition: opacity 0.2s;
}
.scrollbar-dynamic > .scroll-element .scroll-bar {
	background-color: #6c6e71;
	-webkit-border-radius: 7px;
	-moz-border-radius: 7px;
	border-radius: 7px;
}
.scrollbar-dynamic > .scroll-element.scroll-x .scroll-bar {
	bottom: 0;
	height: 7px;
	min-width: 24px;
	top: auto;
}
.scrollbar-dynamic > .scroll-element.scroll-y .scroll-bar {
	left: auto;
	min-height: 24px;
	right: 0;
	width: 7px;
}
.scrollbar-dynamic > .scroll-element.scroll-x .scroll-element_outer {
	bottom: 0;
	top: auto;
	left: 2px;
	-webkit-transition: height 0.2s;
	-moz-transition: height 0.2s;
	-o-transition: height 0.2s;
	-ms-transition: height 0.2s;
	transition: height 0.2s;
}
.scrollbar-dynamic > .scroll-element.scroll-y .scroll-element_outer {
	left: auto;
	right: 0;
	top: 2px;
	-webkit-transition: width 0.2s;
	-moz-transition: width 0.2s;
	-o-transition: width 0.2s;
	-ms-transition: width 0.2s;
	transition: width 0.2s;
}
.scrollbar-dynamic > .scroll-element.scroll-x .scroll-element_size {
	left: -4px;
}
.scrollbar-dynamic > .scroll-element.scroll-y .scroll-element_size {
	top: -4px;
}
.scrollbar-dynamic > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
	left: -11px;
}
.scrollbar-dynamic > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
	top: -11px;
}
.scrollbar-dynamic > .scroll-element.scroll-draggable .scroll-element_outer,
.scrollbar-dynamic > .scroll-element:hover .scroll-element_outer {
	overflow: hidden;
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=70)';
	filter: alpha(opacity=70);
	opacity: 0.7;
}
.scrollbar-dynamic > .scroll-element.scroll-draggable .scroll-element_outer .scroll-element_size,
.scrollbar-dynamic > .scroll-element:hover .scroll-element_outer .scroll-element_size {
	opacity: 1;
}
.scrollbar-dynamic > .scroll-element.scroll-draggable .scroll-element_outer .scroll-bar,
.scrollbar-dynamic > .scroll-element:hover .scroll-element_outer .scroll-bar {
	height: 100%;
	width: 100%;
	-webkit-border-radius: 12px;
	-moz-border-radius: 12px;
	border-radius: 12px;
}
.scrollbar-chrome > .scroll-element,
.scrollbar-chrome > .scroll-element div {
	border: none;
	margin: 0;
	padding: 0;
	position: absolute;
	z-index: 10;
	overflow: hidden;
}
.scrollbar-dynamic > .scroll-element.scroll-x.scroll-draggable .scroll-element_outer,
.scrollbar-dynamic > .scroll-element.scroll-x:hover .scroll-element_outer {
	height: 20px;
	min-height: 7px;
}
.scrollbar-dynamic > .scroll-element.scroll-y.scroll-draggable .scroll-element_outer,
.scrollbar-dynamic > .scroll-element.scroll-y:hover .scroll-element_outer {
	min-width: 7px;
	width: 20px;
}
.scrollbar-chrome > .scroll-element {
	background-color: #fff;
}
.scrollbar-chrome > .scroll-element div {
	display: block;
	height: 100%;
	left: 0;
	top: 0;
	width: 100%;
}
.scrollbar-chrome > .scroll-element .scroll-element_track {
	background: #f1f1f1;
	border: 1px solid #dbdbdb;
}
.scrollbar-chrome > .scroll-element.scroll-x {
	bottom: 0;
	height: 16px;
	left: 0;
	min-width: 100%;
	width: 100%;
}
.scrollbar-chrome > .scroll-element.scroll-y {
	height: 100%;
	min-height: 100%;
	right: 0;
	top: 0;
	width: 16px;
}
.scrollbar-chrome > .scroll-element .scroll-bar {
	background-color: #d9d9d9;
	border: 1px solid #bdbdbd;
	cursor: default;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
}
.scrollbar-chrome > .scroll-element .scroll-bar:hover {
	background-color: #c2c2c2;
	border-color: #a9a9a9;
}
.scrollbar-chrome > .scroll-element.scroll-draggable .scroll-bar {
	background-color: #919191;
	border-color: #7e7e7e;
}
.scrollbar-chrome > .scroll-content.scroll-scrolly_visible {
	left: -16px;
	margin-left: 16px;
}
.scrollbar-chrome > .scroll-content.scroll-scrollx_visible {
	top: -16px;
	margin-top: 16px;
}
.scrollbar-chrome > .scroll-element.scroll-x .scroll-bar {
	height: 8px;
	min-width: 10px;
	top: 3px;
}
.scrollbar-chrome > .scroll-element.scroll-y .scroll-bar {
	left: 3px;
	min-height: 10px;
	width: 8px;
}
.scrollbar-chrome > .scroll-element.scroll-x .scroll-element_outer {
	border-left: 1px solid #dbdbdb;
}
.scrollbar-chrome > .scroll-element.scroll-x .scroll-element_track {
	height: 14px;
	left: -3px;
}
.scrollbar-chrome > .scroll-element.scroll-x .scroll-element_size {
	height: 14px;
	left: -4px;
}
.scrollbar-chrome > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size,
.scrollbar-chrome > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track {
	left: -19px;
}
.scrollbar-chrome > .scroll-element.scroll-y .scroll-element_outer {
	border-top: 1px solid #dbdbdb;
}
.scrollbar-chrome > .scroll-element.scroll-y .scroll-element_track {
	top: -3px;
	width: 14px;
}
.scrollbar-chrome > .scroll-element.scroll-y .scroll-element_size {
	top: -4px;
	width: 14px;
}
.scrollbar-chrome > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size,
.scrollbar-chrome > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track {
	top: -19px;
}
.checkbox label .toggle,
.checkbox-inline .toggle {
	margin-left: -20px;
	margin-right: 5px;
}
.toggle {
	position: relative;
	overflow: hidden;
}
.toggle-group,
.toggle-off,
.toggle-on {
	position: absolute;
	top: 0;
	bottom: 0;
}
.toggle input[type='checkbox'] {
	display: none;
}
.toggle-group {
	width: 200%;
	left: 0;
	transition: left 0.35s;
	-webkit-transition: left 0.35s;
}
.toggle.off .toggle-group {
	left: -100%;
}
.toggle-on {
	left: 0;
	right: 50%;
	margin: 0;
	border: 0;
	border-radius: 0;
}
.toggle-off {
	left: 50%;
	right: 0;
	margin: 0;
	border: 0;
	border-radius: 0;
}
.toggle-handle {
	position: relative;
	margin: 0 auto;
	padding-top: 0;
	padding-bottom: 0;
	height: 100%;
	width: 0;
	border-width: 0 1px;
}
.toggle.btn {
	min-width: 59px;
	min-height: 34px;
}
.toggle-on.btn {
	padding-right: 24px;
}
.toggle-off.btn {
	padding-left: 24px;
}
.toggle.btn-lg {
	min-width: 79px;
	min-height: 45px;
}
.toggle-on.btn-lg {
	padding-right: 31px;
}
.toggle-off.btn-lg {
	padding-left: 31px;
}
.toggle-handle.btn-lg {
	width: 40px;
}
.toggle.btn-sm {
	min-width: 50px;
	min-height: 30px;
}
.toggle-on.btn-sm {
	padding-right: 20px;
}
.toggle-off.btn-sm {
	padding-left: 20px;
}
.toggle.btn-xs {
	min-width: 35px;
	min-height: 22px;
}
.wrapper,
body {
	min-height: 100vh;
	position: relative;
}
.toggle-on.btn-xs {
	padding-right: 12px;
}
.toggle-off.btn-xs {
	padding-left: 12px;
}
.wrapper {
	top: 0;
	height: 100vh;
}
.main-header {
	min-height: 55px;
	width: 100%;
	background: #fff;
	position: fixed;
	z-index: 1001;
	border-bottom: 1px solid #eee;
}
.main-header .logo-header {
	float: left;
	width: 260px;
	height: 55px;
	line-height: 55px;
	border-right: 1px solid #eee;
	color: #333;
	font-size: 18px;
	font-weight: 600;
	padding-left: 25px;
	padding-right: 25px;
	z-index: 1001;
	display: inline-block;
}
.main-header .logo-header .logo {
	width: 100%;
	height: 100%;
}
.main-header .logo-header .navbar-toggler {
	padding-left: 0;
	padding-right: 0;
	opacity: 0;
}
.main-header .logo-header .navbar-toggler .navbar-toggler-icon {
	background: url(../img/menu2.png);
	background-size: cover;
	height: 1em;
	width: 1em;
	opacity: 0.8;
}
.main-header .logo-header .more {
	background: 0 0;
	border: 0;
	font-size: 26px;
	padding: 0;
	opacity: 0;
}
.sidebar {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	width: 260px;
	display: block;
	z-index: 1;
	color: #fff;
	font-weight: 200;
	background: center center #fff;
	background-size: cover;
	border-right: 1px solid #eee;
	box-shadow: 6px 1px 20px rgba(69, 65, 78, 0.1);
}
.sidebar .user {
	margin-top: 12.5px;
	padding-left: 25px;
	padding-right: 25px;
	padding-bottom: 12.5px;
	border-bottom: 1px solid #eee;
	display: block;
}
.sidebar .user .photo {
	width: 40px;
	height: 40px;
	overflow: hidden;
	float: left;
	margin-right: 11px;
	z-index: 5;
	border-radius: 50%;
}
.sidebar .user .photo img {
	width: 100%;
	height: 100%;
}
.sidebar .user .info .case {
	white-space: nowrap;
	display: block;
	position: relative;
}
.sidebar .user .info .case > span {
	font-size: 14px;
	font-weight: 600;
	color: #777;
	letter-spacing: 0.04em;
	display: flex;
	flex-direction: column;
}
.sidebar .user .info .case > span .user-level {
	color: #555;
	font-weight: 700;
	font-size: 13px;
	letter-spacing: 0.05em;
	margin-top: 5px;
}
.sidebar .user .info .case .link-collapse {
	padding: 7px 0;
}
.sidebar .sidebar-wrapper {
	position: relative;
	max-height: calc(100vh - 75px);
	min-height: 100%;
	overflow: auto;
	width: 260px;
	z-index: 4;
	padding-top: 55px;
	padding-bottom: 100px;
}
.sidebar .nav {
	display: block;
	float: none;
	margin-top: 20px;
}
.sidebar .nav .nav-item {
	display: list-item;
}
.sidebar .nav .nav-item.active a {
	color: #575962;
	font-weight: 700;
}
.sidebar .nav .nav-item.active a:before,
.sidebar .nav .nav-item.active:hover a:before {
	background: #1d7af3;
	opacity: 1 !important;
	position: absolute;
	z-index: 1;
	width: 3px;
	height: 100%;
	content: '';
	left: 0;
	top: 0;
}
.sidebar .nav .nav-item.active a i {
	color: #4d7cfe;
}
.sidebar .nav .nav-item a {
	display: flex;
	align-items: center;
	color: #83848a;
	padding: 13px 25px;
	width: 100%;
	font-size: 14px;
	font-weight: 600;
	position: relative;
	margin-bottom: 5px;
}
.sidebar .nav .nav-item a:focus,
.sidebar .nav .nav-item a:hover {
	text-decoration: none;
	background: #fbfbfb;
}
.sidebar .nav .nav-item:hover a:before {
	background: #1d7af3;
	opacity: 0.7;
	position: absolute;
	z-index: 1;
	width: 3px;
	height: 100%;
	content: '';
	left: 0;
	top: 0;
}
.la-ul > li,
.main-panel {
	position: relative;
}
.sidebar .nav .nav-item a i {
	font-size: 23px;
	color: #c3c5ca;
	margin-right: 15px;
	width: 25px;
	text-align: center;
	vertical-align: middle;
	float: left;
}
.main-panel,
.pull-right {
	float: right;
}
.sidebar .nav .nav-item a p {
	font-size: 14px;
	margin-bottom: 0;
	letter-spacing: 0.04em;
	margin-right: 5px;
}
.main-panel {
	width: calc(100% - 260px);
	min-height: 100%;
	background: #f2f3f8;
}
.main-panel .content {
	padding: 30px 15px;
	min-height: calc(100% - 123px);
	margin-top: 55px;
}
.footer {
	border-top: 1px solid #eee;
	padding: 15px;
	background: #fff;
}
.footer .container-fluid {
	display: flex;
	align-items: center;
}
.la,
.la-stack {
	display: inline-block;
}
.la-fw,
.la-li {
	text-align: center;
}
@font-face {
	font-family: LineAwesome;
	src: url(../fonts/line-awesome.eot?v=1.1.);
	src: url(../fonts/line-awesome.eot??v=1.1.#iefix) format('embedded-opentype'),
		url(../fonts/line-awesome.woff2?v=1.1.) format('woff2'),
		url(../fonts/line-awesome.woff?v=1.1.) format('woff'),
		url(../fonts/line-awesome.ttf?v=1.1.) format('truetype'),
		url(../fonts/line-awesome.svg?v=1.1.#fa) format('svg');
	font-weight: 400;
	font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
	@font-face {
		font-family: LineAwesome;
		src: url(../fonts/line-awesome.svg?v=1.1.#fa) format('svg');
	}
}
.la {
	font: normal normal normal 16px/1 LineAwesome;
	font-size: inherit;
	text-decoration: inherit;
	text-rendering: optimizeLegibility;
	text-transform: none;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
}
.la-lg {
	font-size: 1.33333333em;
	line-height: 0.75em;
	vertical-align: -15%;
}
.la-2x {
	font-size: 2em;
}
.la-3x {
	font-size: 3em;
}
.la-4x {
	font-size: 4em;
}
.la-5x {
	font-size: 5em;
}
.la-fw {
	width: 1.28571429em;
}
.la-ul {
	padding-left: 0;
	margin-left: 2.14285714em;
	list-style-type: none;
}
.la-li {
	position: absolute;
	left: -2.14285714em;
	width: 2.14285714em;
	top: 0.14285714em;
}
.la-li.la-lg {
	left: -1.85714286em;
}
.la-border {
	padding: 0.2em 0.25em 0.15em;
	border: 0.08em solid #eee;
	border-radius: 0.1em;
}
.pull-left {
	float: left;
}
.li.pull-left {
	margin-right: 0.3em;
}
.li.pull-right {
	margin-left: 0.3em;
}
.la-spin {
	-webkit-animation: fa-spin 2s infinite linear;
	animation: fa-spin 2s infinite linear;
}
@-webkit-keyframes fa-spin {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}
@keyframes fa-spin {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}
.la-rotate-90 {
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}
.la-rotate-180 {
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}
.la-rotate-270 {
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
	-webkit-transform: rotate(270deg);
	-ms-transform: rotate(270deg);
	transform: rotate(270deg);
}
.la-flip-horizontal {
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
	-webkit-transform: scale(-1, 1);
	-ms-transform: scale(-1, 1);
	transform: scale(-1, 1);
}
.la-flip-vertical {
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
	-webkit-transform: scale(1, -1);
	-ms-transform: scale(1, -1);
	transform: scale(1, -1);
}
:root .la-flip-horizontal,
:root .la-flip-vertical,
:root .la-rotate-180,
:root .la-rotate-270,
:root .la-rotate-90 {
	filter: none;
}
.la-stack {
	position: relative;
	width: 2em;
	height: 2em;
	line-height: 2em;
	vertical-align: middle;
}
.la-stack-1x,
.la-stack-2x {
	position: absolute;
	left: 0;
	width: 100%;
	text-align: center;
}
.la-stack-1x {
	line-height: inherit;
}
.la-stack-2x {
	font-size: 2em;
}
.la-inverse {
	color: #fff;
}
.la-500px:before {
	content: '\f100';
}
.la-adjust:before {
	content: '\f101';
}
.la-adn:before {
	content: '\f102';
}
.la-align-center:before {
	content: '\f103';
}
.la-align-justify:before {
	content: '\f104';
}
.la-align-left:before {
	content: '\f105';
}
.la-align-right:before {
	content: '\f106';
}
.la-amazon:before {
	content: '\f107';
}
.la-ambulance:before {
	content: '\f108';
}
.la-anchor:before {
	content: '\f109';
}
.la-android:before {
	content: '\f10a';
}
.la-angellist:before {
	content: '\f10b';
}
.la-angle-double-down:before {
	content: '\f10c';
}
.la-angle-double-left:before {
	content: '\f10d';
}
.la-angle-double-right:before {
	content: '\f10e';
}
.la-angle-double-up:before {
	content: '\f10f';
}
.la-angle-down:before {
	content: '\f110';
}
.la-angle-left:before {
	content: '\f111';
}
.la-angle-right:before {
	content: '\f112';
}
.la-angle-up:before {
	content: '\f113';
}
.la-apple:before {
	content: '\f114';
}
.la-archive:before {
	content: '\f115';
}
.la-area-chart:before {
	content: '\f116';
}
.la-arrow-circle-down:before {
	content: '\f117';
}
.la-arrow-circle-left:before {
	content: '\f118';
}
.la-arrow-circle-o-down:before {
	content: '\f119';
}
.la-arrow-circle-o-left:before {
	content: '\f11a';
}
.la-arrow-circle-o-right:before {
	content: '\f11b';
}
.la-arrow-circle-o-up:before {
	content: '\f11c';
}
.la-arrow-circle-right:before {
	content: '\f11d';
}
.la-arrow-circle-up:before {
	content: '\f11e';
}
.la-arrow-down:before {
	content: '\f11f';
}
.la-arrow-left:before {
	content: '\f120';
}
.la-arrow-right:before {
	content: '\f121';
}
.la-arrow-up:before {
	content: '\f122';
}
.la-arrows:before {
	content: '\f123';
}
.la-arrows-alt:before {
	content: '\f124';
}
.la-arrows-h:before {
	content: '\f125';
}
.la-arrows-v:before {
	content: '\f126';
}
.la-asterisk:before {
	content: '\f127';
}
.la-at:before {
	content: '\f128';
}
.la-automobile:before {
	content: '\f129';
}
.la-backward:before {
	content: '\f12a';
}
.la-balance-scale:before {
	content: '\f12b';
}
.la-ban:before {
	content: '\f12c';
}
.la-bank:before {
	content: '\f12d';
}
.la-bar-chart:before {
	content: '\f12e';
}
.la-bar-chart-o:before {
	content: '\f12f';
}
.la-barcode:before {
	content: '\f130';
}
.la-bars:before {
	content: '\f131';
}
.la-battery-0:before {
	content: '\f132';
}
.la-battery-1:before {
	content: '\f133';
}
.la-battery-2:before {
	content: '\f134';
}
.la-battery-3:before {
	content: '\f135';
}
.la-battery-4:before {
	content: '\f136';
}
.la-battery-empty:before {
	content: '\f137';
}
.la-battery-full:before {
	content: '\f138';
}
.la-battery-half:before {
	content: '\f139';
}
.la-battery-quarter:before {
	content: '\f13a';
}
.la-battery-three-quarters:before {
	content: '\f13b';
}
.la-bed:before {
	content: '\f13c';
}
.la-beer:before {
	content: '\f13d';
}
.la-behance:before {
	content: '\f13e';
}
.la-behance-square:before {
	content: '\f13f';
}
.la-bell:before {
	content: '\f140';
}
.la-bell-o:before {
	content: '\f141';
}
.la-bell-slash:before {
	content: '\f142';
}
.la-bell-slash-o:before {
	content: '\f143';
}
.la-bicycle:before {
	content: '\f144';
}
.la-binoculars:before {
	content: '\f145';
}
.la-birthday-cake:before {
	content: '\f146';
}
.la-bitbucket:before {
	content: '\f147';
}
.la-bitbucket-square:before {
	content: '\f148';
}
.la-bitcoin:before {
	content: '\f149';
}
.la-black-tie:before {
	content: '\f14a';
}
.la-bold:before {
	content: '\f14b';
}
.la-bolt:before {
	content: '\f14c';
}
.la-bomb:before {
	content: '\f14d';
}
.la-book:before {
	content: '\f14e';
}
.la-bookmark:before {
	content: '\f14f';
}
.la-bookmark-o:before {
	content: '\f150';
}
.la-briefcase:before {
	content: '\f151';
}
.la-btc:before {
	content: '\f152';
}
.la-bug:before {
	content: '\f153';
}
.la-building:before {
	content: '\f154';
}
.la-building-o:before {
	content: '\f155';
}
.la-bullhorn:before {
	content: '\f156';
}
.la-bullseye:before {
	content: '\f157';
}
.la-bus:before {
	content: '\f158';
}
.la-buysellads:before {
	content: '\f159';
}
.la-cab:before {
	content: '\f15a';
}
.la-calculator:before {
	content: '\f15b';
}
.la-calendar:before {
	content: '\f15c';
}
.la-calendar-check-o:before {
	content: '\f15d';
}
.la-calendar-minus-o:before {
	content: '\f15e';
}
.la-calendar-o:before {
	content: '\f15f';
}
.la-calendar-plus-o:before {
	content: '\f160';
}
.la-calendar-times-o:before {
	content: '\f161';
}
.la-camera:before {
	content: '\f162';
}
.la-camera-retro:before {
	content: '\f163';
}
.la-car:before {
	content: '\f164';
}
.la-caret-down:before {
	content: '\f165';
}
.la-caret-left:before {
	content: '\f166';
}
.la-caret-right:before {
	content: '\f167';
}
.la-caret-square-o-down:before,
.la-toggle-down:before {
	content: '\f168';
}
.la-caret-square-o-left:before,
.la-toggle-left:before {
	content: '\f169';
}
.la-caret-square-o-right:before,
.la-toggle-right:before {
	content: '\f16a';
}
.la-caret-square-o-up:before,
.la-toggle-up:before {
	content: '\f16b';
}
.la-caret-up:before {
	content: '\f16c';
}
.la-cart-arrow-down:before {
	content: '\f16d';
}
.la-cart-plus:before {
	content: '\f16e';
}
.la-cc:before {
	content: '\f16f';
}
.la-cc-amex:before {
	content: '\f170';
}
.la-cc-diners-club:before {
	content: '\f171';
}
.la-cc-discover:before {
	content: '\f172';
}
.la-cc-jcb:before {
	content: '\f173';
}
.la-cc-mastercard:before {
	content: '\f174';
}
.la-cc-paypal:before {
	content: '\f175';
}
.la-cc-stripe:before {
	content: '\f176';
}
.la-cc-visa:before {
	content: '\f177';
}
.la-certificate:before {
	content: '\f178';
}
.la-chain:before {
	content: '\f179';
}
.la-chain-broken:before {
	content: '\f17a';
}
.la-check:before {
	content: '\f17b';
}
.la-check-circle:before {
	content: '\f17c';
}
.la-check-circle-o:before {
	content: '\f17d';
}
.la-check-square:before {
	content: '\f17e';
}
.la-check-square-o:before {
	content: '\f17f';
}
.la-chevron-circle-down:before {
	content: '\f180';
}
.la-chevron-circle-left:before {
	content: '\f181';
}
.la-chevron-circle-right:before {
	content: '\f182';
}
.la-chevron-circle-up:before {
	content: '\f183';
}
.la-chevron-down:before {
	content: '\f184';
}
.la-chevron-left:before {
	content: '\f185';
}
.la-chevron-right:before {
	content: '\f186';
}
.la-chevron-up:before {
	content: '\f187';
}
.la-child:before {
	content: '\f188';
}
.la-chrome:before {
	content: '\f189';
}
.la-circle:before {
	content: '\f18a';
}
.la-circle-o:before {
	content: '\f18b';
}
.la-circle-o-notch:before {
	content: '\f18c';
}
.la-circle-thin:before {
	content: '\f18d';
}
.la-clipboard:before {
	content: '\f18e';
}
.la-clock-o:before {
	content: '\f18f';
}
.la-clone:before {
	content: '\f190';
}
.la-close:before {
	content: '\f191';
}
.la-cloud:before {
	content: '\f192';
}
.la-cloud-download:before {
	content: '\f193';
}
.la-cloud-upload:before {
	content: '\f194';
}
.la-cny:before {
	content: '\f195';
}
.la-code:before {
	content: '\f196';
}
.la-code-fork:before {
	content: '\f197';
}
.la-codepen:before {
	content: '\f198';
}
.la-coffee:before {
	content: '\f199';
}
.la-cog:before {
	content: '\f19a';
}
.la-cogs:before {
	content: '\f19b';
}
.la-columns:before {
	content: '\f19c';
}
.la-comment:before {
	content: '\f19d';
}
.la-comment-o:before {
	content: '\f19e';
}
.la-commenting:before {
	content: '\f19f';
}
.la-commenting-o:before {
	content: '\f1a0';
}
.la-comments:before {
	content: '\f1a1';
}
.la-comments-o:before {
	content: '\f1a2';
}
.la-compass:before {
	content: '\f1a3';
}
.la-compress:before {
	content: '\f1a4';
}
.la-connectdevelop:before {
	content: '\f1a5';
}
.la-contao:before {
	content: '\f1a6';
}
.la-copy:before {
	content: '\f1a7';
}
.la-copyright:before {
	content: '\f1a8';
}
.la-creative-commons:before {
	content: '\f1a9';
}
.la-credit-card:before {
	content: '\f1aa';
}
.la-crop:before {
	content: '\f1ab';
}
.la-crosshairs:before {
	content: '\f1ac';
}
.la-css3:before {
	content: '\f1ad';
}
.la-cube:before {
	content: '\f1ae';
}
.la-cubes:before {
	content: '\f1af';
}
.la-cut:before {
	content: '\f1b0';
}
.la-cutlery:before {
	content: '\f1b1';
}
.la-dashboard:before {
	content: '\f1b2';
}
.la-dashcube:before {
	content: '\f1b3';
}
.la-database:before {
	content: '\f1b4';
}
.la-dedent:before {
	content: '\f1b5';
}
.la-delicious:before {
	content: '\f1b6';
}
.la-desktop:before {
	content: '\f1b7';
}
.la-deviantart:before {
	content: '\f1b8';
}
.la-diamond:before {
	content: '\f1b9';
}
.la-digg:before {
	content: '\f1ba';
}
.la-dollar:before {
	content: '\f1bb';
}
.la-dot-circle-o:before {
	content: '\f1bc';
}
.la-download:before {
	content: '\f1bd';
}
.la-dribbble:before {
	content: '\f1be';
}
.la-dropbox:before {
	content: '\f1bf';
}
.la-drupal:before {
	content: '\f1c0';
}
.la-edit:before {
	content: '\f1c1';
}
.la-eject:before {
	content: '\f1c2';
}
.la-ellipsis-h:before {
	content: '\f1c3';
}
.la-ellipsis-v:before {
	content: '\f1c4';
}
.la-empire:before,
.la-ge:before {
	content: '\f1c5';
}
.la-envelope:before {
	content: '\f1c6';
}
.la-envelope-o:before {
	content: '\f1c7';
}
.la-envelope-square:before {
	content: '\f1c8';
}
.la-eraser:before {
	content: '\f1c9';
}
.la-eur:before {
	content: '\f1ca';
}
.la-euro:before {
	content: '\f1cb';
}
.la-exchange:before {
	content: '\f1cc';
}
.la-exclamation:before {
	content: '\f1cd';
}
.la-exclamation-circle:before {
	content: '\f1ce';
}
.la-exclamation-triangle:before {
	content: '\f1cf';
}
.la-expand:before {
	content: '\f1d0';
}
.la-expeditedssl:before {
	content: '\f1d1';
}
.la-external-link:before {
	content: '\f1d2';
}
.la-external-link-square:before {
	content: '\f1d3';
}
.la-eye:before {
	content: '\f1d4';
}
.la-eye-slash:before {
	content: '\f1d5';
}
.la-eyedropper:before {
	content: '\f1d6';
}
.la-facebook-f:before,
.la-facebook:before {
	content: '\f1d7';
}
.la-facebook-official:before {
	content: '\f1d8';
}
.la-facebook-square:before {
	content: '\f1d9';
}
.la-fast-backward:before {
	content: '\f1da';
}
.la-fast-forward:before {
	content: '\f1db';
}
.la-fax:before {
	content: '\f1dc';
}
.la-female:before {
	content: '\f1dd';
}
.la-fighter-jet:before {
	content: '\f1de';
}
.la-file:before {
	content: '\f1df';
}
.la-file-archive-o:before {
	content: '\f1e0';
}
.la-file-audio-o:before {
	content: '\f1e1';
}
.la-file-code-o:before {
	content: '\f1e2';
}
.la-file-excel-o:before {
	content: '\f1e3';
}
.la-file-image-o:before {
	content: '\f1e4';
}
.la-file-movie-o:before {
	content: '\f1e5';
}
.la-file-o:before {
	content: '\f1e6';
}
.la-file-pdf-o:before {
	content: '\f1e7';
}
.la-file-photo-o:before {
	content: '\f1e8';
}
.la-file-picture-o:before {
	content: '\f1e9';
}
.la-file-powerpoint-o:before {
	content: '\f1ea';
}
.la-file-sound-o:before {
	content: '\f1eb';
}
.la-file-text:before {
	content: '\f1ec';
}
.la-file-text-o:before {
	content: '\f1ed';
}
.la-file-video-o:before {
	content: '\f1ee';
}
.la-file-word-o:before {
	content: '\f1ef';
}
.la-file-zip-o:before {
	content: '\f1f0';
}
.la-files-o:before {
	content: '\f1f1';
}
.la-film:before {
	content: '\f1f2';
}
.la-filter:before {
	content: '\f1f3';
}
.la-fire:before {
	content: '\f1f4';
}
.la-fire-extinguisher:before {
	content: '\f1f5';
}
.la-firefox:before {
	content: '\f1f6';
}
.la-flag:before {
	content: '\f1f7';
}
.la-flag-checkered:before {
	content: '\f1f8';
}
.la-flag-o:before {
	content: '\f1f9';
}
.la-flash:before {
	content: '\f1fa';
}
.la-flask:before {
	content: '\f1fb';
}
.la-flickr:before {
	content: '\f1fc';
}
.la-floppy-o:before {
	content: '\f1fd';
}
.la-folder:before {
	content: '\f1fe';
}
.la-folder-o:before {
	content: '\f1ff';
}
.la-folder-open:before {
	content: '\f200';
}
.la-folder-open-o:before {
	content: '\f201';
}
.la-font:before {
	content: '\f202';
}
.la-fonticons:before {
	content: '\f203';
}
.la-forumbee:before {
	content: '\f204';
}
.la-forward:before {
	content: '\f205';
}
.la-foursquare:before {
	content: '\f206';
}
.la-frown-o:before {
	content: '\f207';
}
.la-futbol-o:before,
.la-soccer-ball-o:before {
	content: '\f208';
}
.la-gamepad:before {
	content: '\f209';
}
.la-gavel:before {
	content: '\f20a';
}
.la-gbp:before {
	content: '\f20b';
}
.la-gear:before {
	content: '\f20c';
}
.la-gears:before {
	content: '\f20d';
}
.la-genderless:before {
	content: '\f20e';
}
.la-get-pocket:before {
	content: '\f20f';
}
.la-gg:before {
	content: '\f210';
}
.la-gg-circle:before {
	content: '\f211';
}
.la-gift:before {
	content: '\f212';
}
.la-git:before {
	content: '\f213';
}
.la-git-square:before {
	content: '\f214';
}
.la-github:before {
	content: '\f215';
}
.la-github-alt:before {
	content: '\f216';
}
.la-github-square:before {
	content: '\f217';
}
.la-glass:before {
	content: '\f218';
}
.la-globe:before {
	content: '\f219';
}
.la-google:before {
	content: '\f21a';
}
.la-google-plus:before {
	content: '\f21b';
}
.la-google-plus-square:before {
	content: '\f21c';
}
.la-google-wallet:before {
	content: '\f21d';
}
.la-graduation-cap:before {
	content: '\f21e';
}
.la-gittip:before,
.la-gratipay:before {
	content: '\f21f';
}
.la-group:before {
	content: '\f220';
}
.la-h-square:before {
	content: '\f221';
}
.la-hacker-news:before {
	content: '\f222';
}
.la-hand-grab-o:before {
	content: '\f223';
}
.la-hand-lizard-o:before {
	content: '\f224';
}
.la-hand-o-down:before {
	content: '\f225';
}
.la-hand-o-left:before {
	content: '\f226';
}
.la-hand-o-right:before {
	content: '\f227';
}
.la-hand-o-up:before {
	content: '\f228';
}
.la-hand-paper-o:before {
	content: '\f229';
}
.la-hand-peace-o:before {
	content: '\f22a';
}
.la-hand-pointer-o:before {
	content: '\f22b';
}
.la-hand-rock-o:before {
	content: '\f22c';
}
.la-hand-scissors-o:before {
	content: '\f22d';
}
.la-hand-spock-o:before {
	content: '\f22e';
}
.la-hand-stop-o:before {
	content: '\f22f';
}
.la-hdd-o:before {
	content: '\f230';
}
.la-header:before {
	content: '\f231';
}
.la-headphones:before {
	content: '\f232';
}
.la-heart:before {
	content: '\f233';
}
.la-heart-o:before {
	content: '\f234';
}
.la-heartbeat:before {
	content: '\f235';
}
.la-history:before {
	content: '\f236';
}
.la-home:before {
	content: '\f237';
}
.la-hospital-o:before {
	content: '\f238';
}
.la-hotel:before {
	content: '\f239';
}
.la-hourglass:before {
	content: '\f23a';
}
.la-hourglass-1:before {
	content: '\f23b';
}
.la-hourglass-2:before {
	content: '\f23c';
}
.la-hourglass-3:before {
	content: '\f23d';
}
.la-hourglass-end:before {
	content: '\f23e';
}
.la-hourglass-half:before {
	content: '\f23f';
}
.la-hourglass-o:before {
	content: '\f240';
}
.la-hourglass-start:before {
	content: '\f241';
}
.la-houzz:before {
	content: '\f242';
}
.la-html5:before {
	content: '\f243';
}
.la-i-cursor:before {
	content: '\f244';
}
.la-ils:before {
	content: '\f245';
}
.la-image:before {
	content: '\f246';
}
.la-inbox:before {
	content: '\f247';
}
.la-indent:before {
	content: '\f248';
}
.la-industry:before {
	content: '\f249';
}
.la-info:before {
	content: '\f24a';
}
.la-info-circle:before {
	content: '\f24b';
}
.la-inr:before {
	content: '\f24c';
}
.la-instagram:before {
	content: '\f24d';
}
.la-institution:before {
	content: '\f24e';
}
.la-internet-explorer:before {
	content: '\f24f';
}
.la-ioxhost:before {
	content: '\f250';
}
.la-italic:before {
	content: '\f251';
}
.la-joomla:before {
	content: '\f252';
}
.la-jpy:before {
	content: '\f253';
}
.la-jsfiddle:before {
	content: '\f254';
}
.la-key:before {
	content: '\f255';
}
.la-keyboard-o:before {
	content: '\f256';
}
.la-krw:before {
	content: '\f257';
}
.la-language:before {
	content: '\f258';
}
.la-laptop:before {
	content: '\f259';
}
.la-lastfm:before {
	content: '\f25a';
}
.la-lastfm-square:before {
	content: '\f25b';
}
.la-leaf:before {
	content: '\f25c';
}
.la-leanpub:before {
	content: '\f25d';
}
.la-legal:before {
	content: '\f25e';
}
.la-lemon-o:before {
	content: '\f25f';
}
.la-level-down:before {
	content: '\f260';
}
.la-level-up:before {
	content: '\f261';
}
.la-life-bouy:before {
	content: '\f262';
}
.la-life-buoy:before {
	content: '\f263';
}
.la-life-ring:before,
.la-support:before {
	content: '\f264';
}
.la-life-saver:before {
	content: '\f265';
}
.la-lightbulb-o:before {
	content: '\f266';
}
.la-line-chart:before {
	content: '\f267';
}
.la-link:before {
	content: '\f268';
}
.la-linkedin:before {
	content: '\f269';
}
.la-linkedin-square:before {
	content: '\f26a';
}
.la-linux:before {
	content: '\f26b';
}
.la-list:before {
	content: '\f26c';
}
.la-list-alt:before {
	content: '\f26d';
}
.la-list-ol:before {
	content: '\f26e';
}
.la-list-ul:before {
	content: '\f26f';
}
.la-location-arrow:before {
	content: '\f270';
}
.la-lock:before {
	content: '\f271';
}
.la-long-arrow-down:before {
	content: '\f272';
}
.la-long-arrow-left:before {
	content: '\f273';
}
.la-long-arrow-right:before {
	content: '\f274';
}
.la-long-arrow-up:before {
	content: '\f275';
}
.la-magic:before {
	content: '\f276';
}
.la-magnet:before {
	content: '\f277';
}
.la-mail-forward:before {
	content: '\f278';
}
.la-mail-reply:before {
	content: '\f279';
}
.la-mail-reply-all:before {
	content: '\f27a';
}
.la-male:before {
	content: '\f27b';
}
.la-map:before {
	content: '\f27c';
}
.la-map-marker:before {
	content: '\f27d';
}
.la-map-o:before {
	content: '\f27e';
}
.la-map-pin:before {
	content: '\f27f';
}
.la-map-signs:before {
	content: '\f280';
}
.la-mars:before {
	content: '\f281';
}
.la-mars-double:before {
	content: '\f282';
}
.la-mars-stroke:before {
	content: '\f283';
}
.la-mars-stroke-h:before {
	content: '\f284';
}
.la-mars-stroke-v:before {
	content: '\f285';
}
.la-maxcdn:before {
	content: '\f286';
}
.la-meanpath:before {
	content: '\f287';
}
.la-medium:before {
	content: '\f288';
}
.la-medkit:before {
	content: '\f289';
}
.la-meh-o:before {
	content: '\f28a';
}
.la-mercury:before {
	content: '\f28b';
}
.la-microphone:before {
	content: '\f28c';
}
.la-microphone-slash:before {
	content: '\f28d';
}
.la-minus:before {
	content: '\f28e';
}
.la-minus-circle:before {
	content: '\f28f';
}
.la-minus-square:before {
	content: '\f290';
}
.la-minus-square-o:before {
	content: '\f291';
}
.la-mobile:before {
	content: '\f292';
}
.la-mobile-phone:before {
	content: '\f293';
}
.la-money:before {
	content: '\f294';
}
.la-moon-o:before {
	content: '\f295';
}
.la-mortar-board:before {
	content: '\f296';
}
.la-motorcycle:before {
	content: '\f297';
}
.la-mouse-pointer:before {
	content: '\f298';
}
.la-music:before {
	content: '\f299';
}
.la-navicon:before {
	content: '\f29a';
}
.la-neuter:before {
	content: '\f29b';
}
.la-newspaper-o:before {
	content: '\f29c';
}
.la-object-group:before {
	content: '\f29d';
}
.la-object-ungroup:before {
	content: '\f29e';
}
.la-odnoklassniki:before {
	content: '\f29f';
}
.la-odnoklassniki-square:before {
	content: '\f2a0';
}
.la-opencart:before {
	content: '\f2a1';
}
.la-openid:before {
	content: '\f2a2';
}
.la-opera:before {
	content: '\f2a3';
}
.la-optin-monster:before {
	content: '\f2a4';
}
.la-outdent:before {
	content: '\f2a5';
}
.la-pagelines:before {
	content: '\f2a6';
}
.la-paint-brush:before {
	content: '\f2a7';
}
.la-paper-plane:before,
.la-send:before {
	content: '\f2a8';
}
.la-paper-plane-o:before,
.la-send-o:before {
	content: '\f2a9';
}
.la-paperclip:before {
	content: '\f2aa';
}
.la-paragraph:before {
	content: '\f2ab';
}
.la-paste:before {
	content: '\f2ac';
}
.la-pause:before {
	content: '\f2ad';
}
.la-paw:before {
	content: '\f2ae';
}
.la-paypal:before {
	content: '\f2af';
}
.la-pencil:before {
	content: '\f2b0';
}
.la-pencil-square:before {
	content: '\f2b1';
}
.la-pencil-square-o:before {
	content: '\f2b2';
}
.la-phone:before {
	content: '\f2b3';
}
.la-phone-square:before {
	content: '\f2b4';
}
.la-photo:before {
	content: '\f2b5';
}
.la-picture-o:before {
	content: '\f2b6';
}
.la-pie-chart:before {
	content: '\f2b7';
}
.la-pied-piper:before {
	content: '\f2b8';
}
.la-pied-piper-alt:before {
	content: '\f2b9';
}
.la-pinterest:before {
	content: '\f2ba';
}
.la-pinterest-p:before {
	content: '\f2bb';
}
.la-pinterest-square:before {
	content: '\f2bc';
}
.la-plane:before {
	content: '\f2bd';
}
.la-play:before {
	content: '\f2be';
}
.la-play-circle:before {
	content: '\f2bf';
}
.la-play-circle-o:before {
	content: '\f2c0';
}
.la-plug:before {
	content: '\f2c1';
}
.la-plus:before {
	content: '\f2c2';
}
.la-plus-circle:before {
	content: '\f2c3';
}
.la-plus-square:before {
	content: '\f2c4';
}
.la-plus-square-o:before {
	content: '\f2c5';
}
.la-power-off:before {
	content: '\f2c6';
}
.la-print:before {
	content: '\f2c7';
}
.la-puzzle-piece:before {
	content: '\f2c8';
}
.la-qq:before {
	content: '\f2c9';
}
.la-qrcode:before {
	content: '\f2ca';
}
.la-question:before {
	content: '\f2cb';
}
.la-question-circle:before {
	content: '\f2cc';
}
.la-quote-left:before {
	content: '\f2cd';
}
.la-quote-right:before {
	content: '\f2ce';
}
.la-ra:before {
	content: '\f2cf';
}
.la-random:before {
	content: '\f2d0';
}
.la-rebel:before {
	content: '\f2d1';
}
.la-recycle:before {
	content: '\f2d2';
}
.la-reddit:before {
	content: '\f2d3';
}
.la-reddit-square:before {
	content: '\f2d4';
}
.la-refresh:before {
	content: '\f2d5';
}
.la-registered:before {
	content: '\f2d6';
}
.la-renren:before {
	content: '\f2d7';
}
.la-reorder:before {
	content: '\f2d8';
}
.la-repeat:before {
	content: '\f2d9';
}
.la-reply:before {
	content: '\f2da';
}
.la-reply-all:before {
	content: '\f2db';
}
.la-retweet:before {
	content: '\f2dc';
}
.la-rmb:before {
	content: '\f2dd';
}
.la-road:before {
	content: '\f2de';
}
.la-rocket:before {
	content: '\f2df';
}
.la-rotate-left:before {
	content: '\f2e0';
}
.la-rotate-right:before {
	content: '\f2e1';
}
.la-rouble:before {
	content: '\f2e2';
}
.la-feed:before,
.la-rss:before {
	content: '\f2e3';
}
.la-rss-square:before {
	content: '\f2e4';
}
.la-rub:before {
	content: '\f2e5';
}
.la-ruble:before {
	content: '\f2e6';
}
.la-rupee:before {
	content: '\f2e7';
}
.la-safari:before {
	content: '\f2e8';
}
.la-save:before {
	content: '\f2e9';
}
.la-scissors:before {
	content: '\f2ea';
}
.la-search:before {
	content: '\f2eb';
}
.la-search-minus:before {
	content: '\f2ec';
}
.la-search-plus:before {
	content: '\f2ed';
}
.la-sellsy:before {
	content: '\f2ee';
}
.la-server:before {
	content: '\f2ef';
}
.la-share:before {
	content: '\f2f0';
}
.la-share-alt:before {
	content: '\f2f1';
}
.la-share-alt-square:before {
	content: '\f2f2';
}
.la-share-square:before {
	content: '\f2f3';
}
.la-share-square-o:before {
	content: '\f2f4';
}
.la-shekel:before {
	content: '\f2f5';
}
.la-sheqel:before {
	content: '\f2f6';
}
.la-shield:before {
	content: '\f2f7';
}
.la-ship:before {
	content: '\f2f8';
}
.la-shirtsinbulk:before {
	content: '\f2f9';
}
.la-shopping-cart:before {
	content: '\f2fa';
}
.la-sign-in:before {
	content: '\f2fb';
}
.la-sign-out:before {
	content: '\f2fc';
}
.la-signal:before {
	content: '\f2fd';
}
.la-simplybuilt:before {
	content: '\f2fe';
}
.la-sitemap:before {
	content: '\f2ff';
}
.la-skyatlas:before {
	content: '\f300';
}
.la-skype:before {
	content: '\f301';
}
.la-slack:before {
	content: '\f302';
}
.la-sliders:before {
	content: '\f303';
}
.la-slideshare:before {
	content: '\f304';
}
.la-smile-o:before {
	content: '\f305';
}
.la-sort:before,
.la-unsorted:before {
	content: '\f306';
}
.la-sort-alpha-asc:before {
	content: '\f307';
}
.la-sort-alpha-desc:before {
	content: '\f308';
}
.la-sort-amount-asc:before {
	content: '\f309';
}
.la-sort-amount-desc:before {
	content: '\f30a';
}
.la-sort-asc:before,
.la-sort-up:before {
	content: '\f30b';
}
.la-sort-desc:before,
.la-sort-down:before {
	content: '\f30c';
}
.la-sort-numeric-asc:before {
	content: '\f30d';
}
.la-sort-numeric-desc:before {
	content: '\f30e';
}
.la-soundcloud:before {
	content: '\f30f';
}
.la-space-shuttle:before {
	content: '\f310';
}
.la-spinner:before {
	content: '\f311';
}
.la-spoon:before {
	content: '\f312';
}
.la-spotify:before {
	content: '\f313';
}
.la-square:before {
	content: '\f314';
}
.la-square-o:before {
	content: '\f315';
}
.la-stack-exchange:before {
	content: '\f316';
}
.la-stack-overflow:before {
	content: '\f317';
}
.la-star:before {
	content: '\f318';
}
.la-star-half:before {
	content: '\f319';
}
.la-star-half-empty:before,
.la-star-half-full:before,
.la-star-half-o:before {
	content: '\f31a';
}
.la-star-o:before {
	content: '\f31b';
}
.la-steam:before {
	content: '\f31c';
}
.la-steam-square:before {
	content: '\f31d';
}
.la-step-backward:before {
	content: '\f31e';
}
.la-step-forward:before {
	content: '\f31f';
}
.la-stethoscope:before {
	content: '\f320';
}
.la-sticky-note:before {
	content: '\f321';
}
.la-sticky-note-o:before {
	content: '\f322';
}
.la-stop:before {
	content: '\f323';
}
.la-street-view:before {
	content: '\f324';
}
.la-strikethrough:before {
	content: '\f325';
}
.la-stumbleupon:before {
	content: '\f326';
}
.la-stumbleupon-circle:before {
	content: '\f327';
}
.la-subscript:before {
	content: '\f328';
}
.la-subway:before {
	content: '\f329';
}
.la-suitcase:before {
	content: '\f32a';
}
.la-sun-o:before {
	content: '\f32b';
}
.la-superscript:before {
	content: '\f32c';
}
.la-table:before {
	content: '\f32d';
}
.la-tablet:before {
	content: '\f32e';
}
.la-tachometer:before {
	content: '\f32f';
}
.la-tag:before {
	content: '\f330';
}
.la-tags:before {
	content: '\f331';
}
.la-tasks:before {
	content: '\f332';
}
.la-taxi:before {
	content: '\f333';
}
.la-television:before,
.la-tv:before {
	content: '\f334';
}
.la-tencent-weibo:before {
	content: '\f335';
}
.la-terminal:before {
	content: '\f336';
}
.la-text-height:before {
	content: '\f337';
}
.la-text-width:before {
	content: '\f338';
}
.la-th:before {
	content: '\f339';
}
.la-th-large:before {
	content: '\f33a';
}
.la-th-list:before {
	content: '\f33b';
}
.la-thumb-tack:before {
	content: '\f33c';
}
.la-thumbs-down:before {
	content: '\f33d';
}
.la-thumbs-o-down:before {
	content: '\f33e';
}
.la-thumbs-o-up:before {
	content: '\f33f';
}
.la-thumbs-up:before {
	content: '\f340';
}
.la-ticket:before {
	content: '\f341';
}
.la-remove:before,
.la-times:before {
	content: '\f342';
}
.la-times-circle:before {
	content: '\f343';
}
.la-times-circle-o:before {
	content: '\f344';
}
.la-tint:before {
	content: '\f345';
}
.la-toggle-off:before {
	content: '\f346';
}
.la-toggle-on:before {
	content: '\f347';
}
.la-trademark:before {
	content: '\f348';
}
.la-train:before {
	content: '\f349';
}
.la-intersex:before,
.la-transgender:before {
	content: '\f34a';
}
.la-transgender-alt:before {
	content: '\f34b';
}
.la-trash:before {
	content: '\f34c';
}
.la-trash-o:before {
	content: '\f34d';
}
.la-tree:before {
	content: '\f34e';
}
.la-trello:before {
	content: '\f34f';
}
.la-tripadvisor:before {
	content: '\f350';
}
.la-trophy:before {
	content: '\f351';
}
.la-truck:before {
	content: '\f352';
}
.la-try:before {
	content: '\f353';
}
.la-tty:before {
	content: '\f354';
}
.la-tumblr:before {
	content: '\f355';
}
.la-tumblr-square:before {
	content: '\f356';
}
.la-turkish-lira:before {
	content: '\f357';
}
.la-twitch:before {
	content: '\f358';
}
.la-twitter:before {
	content: '\f359';
}
.la-twitter-square:before {
	content: '\f35a';
}
.la-umbrella:before {
	content: '\f35b';
}
.la-underline:before {
	content: '\f35c';
}
.la-undo:before {
	content: '\f35d';
}
.la-university:before {
	content: '\f35e';
}
.la-unlink:before {
	content: '\f35f';
}
.la-unlock:before {
	content: '\f360';
}
.la-unlock-alt:before {
	content: '\f361';
}
.la-upload:before {
	content: '\f362';
}
.la-usd:before {
	content: '\f363';
}
.la-user:before {
	content: '\f364';
}
.la-user-md:before {
	content: '\f365';
}
.la-user-plus:before {
	content: '\f366';
}
.la-user-secret:before {
	content: '\f367';
}
.la-user-times:before {
	content: '\f368';
}
.la-users:before {
	content: '\f369';
}
.la-venus:before {
	content: '\f36a';
}
.la-venus-double:before {
	content: '\f36b';
}
.la-venus-mars:before {
	content: '\f36c';
}
.la-viacoin:before {
	content: '\f36d';
}
.la-video-camera:before {
	content: '\f36e';
}
.la-vimeo:before {
	content: '\f36f';
}
.la-vimeo-square:before {
	content: '\f370';
}
.la-vine:before {
	content: '\f371';
}
.la-vk:before {
	content: '\f372';
}
.la-volume-down:before {
	content: '\f373';
}
.la-volume-off:before {
	content: '\f374';
}
.la-volume-up:before {
	content: '\f375';
}
.la-warning:before {
	content: '\f376';
}
.la-wechat:before {
	content: '\f377';
}
.la-weibo:before {
	content: '\f378';
}
.la-weixin:before {
	content: '\f379';
}
.la-whatsapp:before {
	content: '\f37a';
}
.la-wheelchair:before {
	content: '\f37b';
}
.la-wifi:before {
	content: '\f37c';
}
.la-wikipedia-w:before {
	content: '\f37d';
}
.la-windows:before {
	content: '\f37e';
}
.la-won:before {
	content: '\f37f';
}
.la-wordpress:before {
	content: '\f380';
}
.la-wrench:before {
	content: '\f381';
}
.la-xing:before {
	content: '\f382';
}
.la-xing-square:before {
	content: '\f383';
}
.la-y-combinator:before {
	content: '\f384';
}
.la-y-combinator-square:before {
	content: '\f385';
}
.la-yahoo:before {
	content: '\f386';
}
.la-yc:before {
	content: '\f387';
}
.la-yc-square:before {
	content: '\f388';
}
.la-yelp:before {
	content: '\f389';
}
.la-yen:before {
	content: '\f38a';
}
.la-youtube:before {
	content: '\f38b';
}
.la-youtube-play:before {
	content: '\f38c';
}
.la-youtube-square:before {
	content: '\f38d';
}
.card {
	border-radius: 0;
	background-color: #fff;
	margin-bottom: 30px;
	-webkit-box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
	-moz-box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
	box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
	border: 1px solid #eee;
}
.card .card-header {
	padding: 15px;
	background-color: transparent;
	border-bottom: 1px solid #ebedf2 !important;
}
.card .card-title {
	margin: 0;
	color: #575962;
	font-size: 18px;
	font-weight: 600;
	line-height: 1.6;
}
.card .card-sub {
	display: block;
	margin: 5px 0 10px;
	font-size: 0.9rem;
	background: #f7f8fa;
	color: #575962;
	padding: 0.85rem 1.5rem;
	border-radius: 4px;
}
.card .card-action,
.card .card-footer {
	background-color: transparent;
	line-height: 30px;
	border-top: 1px solid #ebedf2 !important;
}
.card .card-category,
.card label {
	font-weight: 400;
	color: #9a9a9a;
	margin-bottom: 0;
	font-size: 14px;
}
.card .card-category {
	margin-top: 5px;
}
[type='checkbox']:checked + .form-check-sign,
[type='radio']:checked + .form-radio-sign {
	font-weight: 700;
}
.card .card-body {
	padding: 15px 15px 10px;
}
.card .card-footer {
	font-size: 14px;
}
.card .card-action {
	padding: 30px;
	font-size: 14px;
}
.card .card-footer hr {
	margin-top: 5px;
	margin-bottom: 5px;
}
.card .card-footer .legend {
	display: inline-block;
}
.card-stats .card-category {
	margin-top: 0;
}
.card-stats .icon-big {
	font-size: 3em;
	min-height: 64px;
}
.card-tasks .table thead th {
	border-top: 1px solid #f4f4f4;
	background: #fafafa !important;
}
.card-tasks .table .form-check {
	padding: 0 0 0 0.75rem !important;
}
.card-tasks .table .form-check label {
	margin-bottom: 0 !important;
}
.card-tasks .table tbody td:first-child,
.card-tasks .table thead th:first-child {
	padding-left: 15px;
	padding-right: 15px;
}
.card-tasks .table tbody td:last-child,
.card-tasks .table thead th:last-child {
	padding-right: 15px;
}
.card-tasks .card-body .table td {
	font-size: 14px;
	line-height: 1.8;
}
.card-tasks .card-body .table td .btn {
	font-size: 17px;
	opacity: 0.7;
	transition: all 0.3s;
}
.card-tasks .card-body .table td:hover .btn {
	opacity: 1;
}
.card-danger,
.card-default,
.card-info,
.card-primary,
.card-success,
.card-warning {
	color: #fff;
	border: 0;
}
.card-danger .card-header,
.card-default .card-header,
.card-info .card-header,
.card-primary .card-header,
.card-success .card-header,
.card-warning .card-header {
	border-bottom: transparent !important;
}
.card-danger .card-category,
.card-danger .card-title,
.card-danger label,
.card-default .card-category,
.card-default .card-title,
.card-default label,
.card-info .card-category,
.card-info .card-title,
.card-info label,
.card-primary .card-category,
.card-primary .card-title,
.card-primary label,
.card-success .card-category,
.card-success .card-title,
.card-success label,
.card-warning .card-category,
.card-warning .card-title,
.card-warning label {
	color: #fff;
}
.card-danger .icon-big > i,
.card-default .icon-big > i,
.card-info .icon-big > i,
.card-primary .icon-big > i,
.card-success .icon-big > i,
.card-warning .icon-big > i {
	color: #fff !important;
}
.card-danger .card-footer,
.card-default .card-footer,
.card-info .card-footer,
.card-primary .card-footer,
.card-success .card-footer,
.card-warning .card-footer {
	border-top: transparent !important;
}
.card-default {
	background: #607d8b;
}
.card-primary {
	background: #1d62f0;
}
.card-info {
	background: #23ccef;
}
.card-success {
	background: #59d05d;
}
.card-warning {
	background: #fbad4c;
}
.card-danger {
	background: #ff646d;
}
.progress-card {
	margin-bottom: 25px;
}
.row-card-no-pd {
	margin-left: 0;
	margin-right: 0;
	background: #fff;
	margin-bottom: 30px;
	padding-top: 30px;
	padding-bottom: 30px;
	position: relative;
}
.row-card-no-pd .card {
	margin-bottom: 0;
	border-width: 0;
	box-shadow: none;
	position: unset;
}
.row-card-no-pd [class*='col-'] .card:after {
	position: absolute;
	height: calc(100%);
	width: 1px;
	background: #eee;
	content: '';
	right: 0;
}
.row-card-no-pd [class*='col-'] .card:after:last-child {
	width: 0;
}
.form-button-action {
	display: inline-flex;
}
.select-all-checkbox + .form-check-sign:before {
	background: #ccc !important;
	border-color: #ccc !important;
}
[type='checkbox']:checked,
[type='checkbox']:not(:checked) {
	position: absolute;
	left: -9999px;
}
[type='checkbox']:checked + .form-check-sign,
[type='checkbox']:not(:checked) + .form-check-sign {
	position: relative;
	padding-left: 1.65em;
	color: #777;
	cursor: pointer;
}
[type='checkbox']:checked + .form-check-sign:before,
[type='checkbox']:not(:checked) + .form-check-sign:before {
	content: '';
	position: absolute;
	left: 0;
	top: 1px;
	width: 16px;
	height: 16px;
	border: 1px solid #ccc;
	background: 0 0;
	border-radius: 4px;
}
[type='checkbox']:checked + .form-check-sign:after,
[type='checkbox']:not(:checked) + .form-check-sign:after {
	content: '\f17b';
	display: inline-block;
	position: absolute;
	top: -1px;
	left: 2px;
	width: 18px;
	height: 18px;
	text-align: center;
	font-size: 1.5em;
	line-height: 0.8;
	color: #4d7cfe;
	transition: all 0.2s;
	font-family: LineAwesome;
}
[type='checkbox']:not(:checked) + .form-check-sign:after {
	opacity: 0;
	transform: scale(0);
}
[type='checkbox']:checked + .form-check-sign:after {
	opacity: 1;
	transform: scale(1);
}
[type='checkbox']:disabled:checked + .form-check-sign:before,
[type='checkbox']:disabled:not(:checked) + .form-check-sign:before {
	box-shadow: none;
	border-color: #bbb;
	background-color: #ddd;
}
[type='checkbox']:disabled:checked + .form-check-sign:after {
	color: #999;
}
[type='checkbox']:disabled + .form-check-sign {
	color: #aaa;
}
[type='checkbox']:checked:focus + .form-check-sign:before,
[type='checkbox']:not(:checked):focus + .form-check-sign:before {
	border: 1px solid #ccc;
}
.form-check-sign:hover:before {
	border: 1px solid #ccc !important;
}
.form-check {
	padding-left: 0.75rem;
}
.form-check-input {
	position: relative;
	margin-top: 0.3rem;
}
[type='radio']:checked,
[type='radio']:not(:checked) {
	position: absolute;
	left: -9999px;
}
[type='radio']:checked + .form-radio-sign,
[type='radio']:not(:checked) + .form-radio-sign {
	position: relative;
	padding-left: 1.65em;
	color: #777;
	cursor: pointer;
}
[type='radio']:not(:checked) + .form-radio-sign:before {
	content: '\f18a';
	font-size: 22px;
	font-family: LineAwesome;
	position: absolute;
	left: 0;
	top: auto;
	background: 0 0;
	line-height: 1;
}
[type='radio']:checked + .form-radio-sign:before {
	content: '\f18a';
	font-size: 22px;
	font-family: LineAwesome;
	position: absolute;
	left: 0;
	top: auto;
	background: 0 0;
	line-height: 1;
	display: none;
}
[type='radio']:checked + .form-radio-sign:after,
[type='radio']:not(:checked) + .form-radio-sign:after {
	content: '\f1bc';
	position: absolute;
	left: 0;
	top: auto;
	text-align: center;
	font-size: 22px;
	color: #4d7cfe;
	transition: all 0.2s;
	line-height: 1;
	font-family: LineAwesome;
}
.caret,
.chart-pie:after,
.chart-pie:before {
	content: '';
}
.form-radio-input,
.has-feedback {
	position: relative;
}
[type='radio']:not(:checked) + .form-radio-sign:after {
	opacity: 0;
	transform: scale(0);
}
[type='radio']:checked + .form-radio-sign:after {
	opacity: 1;
	transform: scale(1);
}
[type='radio']:disabled:checked + .form-radio-sign:before,
[type='radio']:disabled:not(:checked) + .form-radio-sign:before {
	box-shadow: none;
	opacity: 0.65;
}
[type='radio']:disabled:checked + .form-radio-sign:after {
	opacity: 0.65;
}
[type='radio']:disabled + .form-radio-sign {
	color: #aaa;
	opacity: 0.65;
}
[type='radio']:checked:focus + .form-radio-sign:before,
[type='radio']:not(:checked):focus + .form-radio-sign:before {
	border: 1px solid #ccc;
}
.form-radio {
	padding-left: 0.75rem;
}
.form-radio-input {
	margin-top: 0.3rem;
}
.form-check,
.form-group {
	margin-bottom: 0;
	padding: 15px 10px;
}
.form-check label,
.form-group label {
	margin-bottom: 0.5rem !important;
	color: #3f4047 !important;
	font-weight: 400;
	font-size: 1rem;
}
.form-inline label {
	margin-bottom: 0 !important;
}
.input-fixed {
	width: 200px;
}
.form-control.input-full {
	width: 100% !important;
}
.has-success .form-control {
	border-color: #59d05d;
	color: #59d05d;
}
.has-error .form-control {
	border-color: #ff646d;
	color: #ff646d;
}
.form-control-feedback {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 20px;
}
.has-success .form-control-feedback {
	color: #59d05d;
}
.has-error .form-control-feedback {
	color: #ff646d;
}
.input-group.has-icon {
	border-radius: 0.25rem;
	border: 1px solid #ced4da;
}
.input-group.has-icon.has-success,
.input-group.has-success {
	border-color: 1px solid #59d05d !important;
	color: #59d05d;
}
.input-group.has-error,
.input-group.has-icon.has-error {
	border: 1px solid #ff646d !important;
	color: #ff646d;
}
.input-group.has-icon .form-control {
	border-radius: 0.25rem;
	border: 0;
	position: relative;
}
.input-group.has-icon .input-group-icon {
	background: 0 0;
	border: 0;
}
.input-square {
	border-radius: 0 !important;
}
.input-pill {
	border-radius: 1.3rem !important;
}
.form-control:disabled,
.form-control[readonly],
.input-solid {
	background: #e8e8e8 !important;
	border-color: #e8e8e8 !important;
}
.form-control:disabled,
.form-control[readonly] {
	opacity: 0.6 !important;
}
.table {
	color: #555 !important;
}
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
	vertical-align: middle;
}
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th {
	padding: 8px;
}
.table thead th {
	color: #554;
	border-bottom-width: 2px;
}
.table td,
.table th {
	font-size: 14px;
	border-color: #ebedf2 !important;
	padding: 0.75rem !important;
}
.table-full-width {
	margin-left: -15px;
	margin-right: -15px;
}
.table-bordered-bd-default td,
.table-bordered-bd-default th {
	border: 1px solid #607d8b !important;
}
.table-bordered-bd-primary td,
.table-bordered-bd-primary th {
	border: 1px solid #1d62f0 !important;
}
.table-bordered-bd-info td,
.table-bordered-bd-info th {
	border: 1px solid #23ccef !important;
}
.table-bordered-bd-success td,
.table-bordered-bd-success th {
	border: 1px solid #59d05d !important;
}
.table-bordered-bd-warning td,
.table-bordered-bd-warning th {
	border: 1px solid #fbad4c !important;
}
.table-bordered-bd-danger td,
.table-bordered-bd-danger th {
	border: 1px solid #ff646d !important;
}
.table-striped td,
.table-striped th {
	border-top: 0 !important;
	border-bottom: 0 !important;
}
.table-head-bg-default {
	border: 1px solid #607d8b !important;
}
.table-head-bg-primary {
	border: 1px solid #1d62f0 !important;
}
.table-head-bg-info {
	border: 1px solid #23ccef !important;
}
.table-head-bg-success {
	border: 1px solid #59d05d !important;
}
.table-head-bg-warning {
	border: 1px solid #fbad4c !important;
}
.table-head-bg-danger {
	border: 1px solid #ff646d !important;
}
.table-head-bg-default thead th,
.table-striped-bg-default tbody tr:nth-of-type(odd) {
	background: #607d8b !important;
	color: #fff !important;
	border: 0 !important;
}
.table-head-bg-primary thead th,
.table-striped-bg-primary tbody tr:nth-of-type(odd) {
	background: #1d62f0 !important;
	color: #fff !important;
	border: 0 !important;
}
.table-head-bg-info thead th,
.table-striped-bg-info tbody tr:nth-of-type(odd) {
	background: #23ccef !important;
	color: #fff !important;
	border: 0 !important;
}
.table-head-bg-success thead th,
.table-striped-bg-success tbody tr:nth-of-type(odd) {
	background: #59d05d !important;
	color: #fff !important;
	border: 0 !important;
}
.table-head-bg-warning thead th,
.table-striped-bg-warning tbody tr:nth-of-type(odd) {
	background: #fbad4c !important;
	color: #fff !important;
	border: 0 !important;
}
.table-head-bg-danger thead th,
.table-striped-bg-danger tbody tr:nth-of-type(odd) {
	background: #ff646d !important;
	color: #fff !important;
	border: 0 !important;
}
.table-responsive {
	display: block;
	width: 100%;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
}
.navbar .navbar-nav .nav-item .nav-link {
	display: inline-block;
	vertical-align: middle;
	color: #666;
	line-height: 55px;
	letter-spacing: 0.04em;
	padding: 0 20px 0 0;
	position: relative;
	font-size: 14px;
	font-weight: 500;
	text-align: center;
}
.navbar .navbar-nav .nav-item .nav-link i {
	font-size: 22px;
	width: 30px;
	vertical-align: middle;
}
.navbar-expand-lg .navbar-nav .dropdown-menu {
	left: auto;
	right: 0;
}
.dropdown-item {
	font-size: 14px;
}
.navbar .navbar-nav .notification {
	position: absolute;
	background-color: #ff646d;
	text-align: center;
	border-radius: 10px;
	min-width: 17px;
	height: 17px;
	font-size: 11px;
	color: #fff;
	font-weight: 700;
	line-height: 17px;
	top: 11px;
	right: 12px;
}
.chart,
.chart-pie {
	position: relative;
}
.navbar-header {
	padding: 0 15px;
}
.navbar-header .dropdown-toggle {
	line-height: 55px;
}
.navbar-header .dropdown-toggle::after {
	margin-left: 0;
	vertical-align: middle;
	color: #555;
}
.profile-pic:focus,
.profile-pic:hover {
	text-decoration: none;
}
.hidden-caret .dropdown-toggle::after {
	display: none !important;
}
.profile-pic .img-circle {
	border-radius: 50%;
}
.profile-pic span {
	font-size: 14px;
	font-weight: 600;
	padding: 0 10px;
	color: #555;
}
.nav-search .input-group {
	border: 1px solid #eee;
	background: #eee;
	border-radius: 50px;
}
.nav-search .input-group:focus,
.nav-search .input-group:hover {
	border: 1px solid #ddd;
}
.nav-search .form-control {
	border: 0;
	background: 0 0;
	font-size: 14px;
}
.nav-search .input-group-text {
	border: 0;
	background: 0 0;
}
.nav-search .search-icon {
	font-size: 21px;
	color: #666;
}
.badge {
	border-radius: 50px;
	margin-left: auto;
	line-height: 1;
	padding: 6px 10px;
	vertical-align: middle;
	font-weight: 700;
	font-size: 11px;
}
.badge-count {
	border: 1px solid #ddd;
}
.badge-default {
	background: #607d8b;
	color: #fff !important;
}
.badge-primary {
	background: #1d62f0;
}
.badge-info {
	background: #23ccef;
}
.badge-success {
	background-color: #59d05d;
}
.badge-warning {
	background: #fbad4c;
	color: #fff !important;
}
.badge-danger {
	background-color: #ff646d;
}
.dropdown-menu {
	border: 1px solid rgba(120, 130, 140, 0.13);
	border-radius: 0;
	box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05) !important;
	-webkit-box-shadow: 0 !important;
	-moz-box-shadow: 0 !important;
	padding-bottom: 8px;
	margin-top: 0;
}
.dropdown-title {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	color: #263238;
	font-size: 15px;
	font-weight: 800;
	padding: 5px 20px 10px;
	text-align: center;
}
.notif-box {
	width: 280px;
	padding-bottom: 0;
}
.notif-box .notif-center a {
	display: flex;
	border-bottom: 1px solid #eaeaea;
	color: #4d585f;
}
.notif-box .notif-center a:hover {
	text-decoration: none;
	background: #fafafa;
	transition: all 0.2s;
}
.notif-box .notif-center a .notif-icon,
.notif-box .notif-center a .notif-img {
	display: inline-flex;
	width: 40px;
	height: 40px;
	margin: 10px;
	align-items: center;
	justify-content: center;
	background: #eee;
	border-radius: 50%;
}
.notif-box .notif-center a .notif-img img {
	width: 100%;
	height: 100%;
	border-radius: 50%;
}
.notif-box .notif-center a .notif-icon.notif-danger,
.notif-box .notif-center a .notif-icon.notif-default,
.notif-box .notif-center a .notif-icon.notif-info,
.notif-box .notif-center a .notif-icon.notif-primary,
.notif-box .notif-center a .notif-icon.notif-success,
.notif-box .notif-center a .notif-icon.notif-warning {
	color: #fff !important;
}
.notif-box .notif-center a .notif-icon.notif-default {
	background: #607d8b !important;
}
.notif-box .notif-center a .notif-icon.notif-primary {
	background: #1d62f0 !important;
}
.notif-box .notif-center a .notif-icon.notif-info {
	background: #23ccef !important;
}
.notif-box .notif-center a .notif-icon.notif-success {
	background: #59d05d !important;
}
.notif-box .notif-center a .notif-icon.notif-warning {
	background: #fbad4c !important;
}
.notif-box .notif-center a .notif-icon.notif-danger {
	background: #ff646d !important;
}
.notif-box .notif-center a .notif-icon i {
	font-size: 21px;
}
.notif-box .notif-center a .notif-content {
	padding: 10px 15px 10px 0;
}
.notif-box .notif-center a .notif-content .block {
	font-size: 14px;
	line-height: 20px;
	display: block;
}
.notif-box .notif-center a .notif-content .time {
	color: #7d8c95;
	font-size: 12px;
}
.notif-box .see-all,
.notif-box .see-all:hover {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 20px;
	color: #555;
	font-size: 15px;
	font-weight: 400;
	text-decoration: none;
}
.notif-box .see-all i {
	float: right;
}
.dropdown-user {
	width: 280px;
}
.user-box {
	display: flex;
	padding: 0.25rem 1rem;
}
.user-box .u-img {
	width: 80px;
}
.user-box .u-img img {
	width: 100%;
	border-radius: 5px;
}
.user-box .u-text {
	padding: 0 10px;
}
.user-box .u-text h4 {
	margin-bottom: 0;
	font-size: 18px;
}
.user-box .u-text .text-muted {
	font-size: 13px;
	margin-bottom: 5px;
}
.user-box .u-text .btn {
	font-size: 12px;
}
.caret {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	border-top: 0.3em solid;
	border-right: 0.3em solid transparent;
	border-bottom: 0;
	border-left: 0.3em solid transparent;
}
.chart {
	margin: 30px 0;
}
.ct-chart-donut .ct-label,
.ct-chart-pie .ct-label {
	dominant-baseline: central;
	fill: #fff !important;
	color: #fff !important;
}
.chart-circle {
	display: flex;
	justify-content: center;
}
.chart-circle .circles-text {
	font-size: 28px !important;
}
.color-box {
	width: 15px;
	height: 15px;
	border-radius: 2px;
	display: inline-block;
	float: left;
	padding-top: 3px;
	margin: 2px 5px 0 0;
}
.chart-pie {
	display: block;
	width: 100%;
}
.chart-pie:before {
	display: block;
	float: left;
	width: 0;
	height: 0;
	padding-bottom: 75%;
}
.chart-pie:after {
	display: table;
	clear: both;
}
.chart-pie svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.alert {
	border: 0;
	color: #fff;
	position: relative;
	padding-left: 65px;
}
.alert [data-notify='icon'] {
	font-size: 30px;
	display: block;
	left: 15px;
	position: absolute;
	top: 50%;
	margin-top: -15px;
}
.alert [data-notify='message'],
.btn {
	font-size: 14px;
}
.alert [data-notify='title'] {
	display: block;
	font-weight: 700;
}
.alert .close {
	background: rgba(255, 255, 255, 0.8);
	width: 25px;
	height: 25px;
	line-height: 25px;
	top: auto !important;
	border-radius: 50%;
}
.alert-primary {
	background-color: #1d62f0;
}
.alert-info {
	background-color: #23ccef;
}
.alert-success {
	background-color: #59d05d;
}
.alert-warning {
	background-color: #fbad4c;
}
.alert-danger {
	background-color: #ff646d;
}
.btn {
	padding: 8px 16px;
	opacity: 0.9;
	border-radius: 3px;
}
.btn:focus,
.btn:hover {
	opacity: 1;
}
.btn-lg {
	font-size: 18px;
	border-radius: 6px;
	padding: 10px 30px;
	font-weight: 400;
}
.btn-sm {
	font-size: 12px;
	padding: 7px 13px;
}
.btn-xs {
	font-size: 11px;
	padding: 5px 9px;
}
.btn.disabled:hover,
.btn:hover:disabled {
	opacity: 0.65;
}
.btn-default,
.btn-default:disabled,
.btn-default:focus,
.btn-default:hover {
	background: #607d8b !important;
	color: #fff !important;
}
.btn-primary,
.btn-primary:disabled,
.btn-primary:focus,
.btn-primary:hover {
	background: #1d62f0 !important;
	border-color: #1d62f0 !important;
}
.btn-info,
.btn-info:disabled,
.btn-info:focus,
.btn-info:hover {
	background: #23ccef !important;
	border-color: #23ccef !important;
}
.btn-success,
.btn-success:disabled,
.btn-success:focus,
.btn-success:hover {
	background: #59d05d !important;
	border-color: #59d05d !important;
}
.btn-warning,
.btn-warning:disabled,
.btn-warning:focus,
.btn-warning:hover {
	background: #fbad4c !important;
	border-color: #fbad4c !important;
	color: #fff !important;
}
.btn-danger,
.btn-danger:disabled,
.btn-danger:focus,
.btn-danger:hover {
	background: #ff646d !important;
	border-color: #ff646d !important;
}
.btn-border,
.btn-border:focus,
.btn-border:hover {
	background: 0 0 !important;
	font-weight: 600 !important;
}
.btn-default.btn-border {
	color: #607d8b !important;
	border: 2px solid #607d8b !important;
}
.btn-primary.btn-border {
	color: #1d62f0 !important;
	border: 2px solid #1d62f0 !important;
}
.btn-info.btn-border {
	color: #23ccef !important;
	border: 2px solid #23ccef !important;
}
.btn-success.btn-border {
	color: #59d05d !important;
	border: 2px solid #59d05d !important;
}
.btn-warning.btn-border {
	color: #fbad4c !important;
	border: 2px solid #fbad4c !important;
}
.btn-danger.btn-border {
	color: #ff646d !important;
	border: 2px solid #ff646d !important;
}
.btn-round {
	border-radius: 100px !important;
}
.btn-link,
.btn-link:focus,
.btn-link:hover {
	background: 0 0 !important;
	border: 0 !important;
}
.btn-link:focus,
.btn-link:hover {
	text-decoration: underline !important;
}
.btn-default.btn-link,
.btn-default.btn-link:hover {
	color: #607d8b !important;
}
.btn-primary.btn-link,
.btn-primary.btn-link:hover {
	color: #1d62f0 !important;
}
.btn-info.btn-link,
.btn-info.btn-link:hover {
	color: #23ccef !important;
}
.btn-success.btn-link,
.btn-success.btn-link:hover {
	color: #59d05d !important;
}
.btn-warning.btn-link,
.btn-warning.btn-link:hover {
	color: #fbad4c !important;
}
.btn-danger.btn-link,
.btn-danger.btn-link:hover {
	color: #ff646d !important;
}
.toggle-on.btn {
	color: #fff !important;
}
.toggle-handle,
.toggle-handle:hover {
	background: #fff !important;
}
.btn-round .toggle-handle {
	border-radius: 50px;
}
.btn-rounded {
	border-radius: 60px !important;
}
.btn-simple-default,
.btn-simple-default:hover {
	color: #607d8b;
	text-decoration: none;
}
.btn-simple-primary,
.btn-simple-primary:hover {
	color: #1d62f0;
	text-decoration: none;
}
.btn-simple-info,
.btn-simple-info:hover {
	color: #23ccef;
	text-decoration: none;
}
.btn-simple-danger,
.btn-simple-danger:hover {
	color: #ff646d;
	text-decoration: none;
}
.btn-simple-warning,
.btn-simple-warning:hover {
	color: #fbad4c;
	text-decoration: none;
}
.btn-simple-success,
.btn-simple-success:hover {
	color: #59d05d;
	text-decoration: none;
}
.btn-full {
	width: 100%;
}
.btn-no-radius {
	border-radius: 0;
}
.nav-pills > li:first-child > a {
	border-radius: 4px 0 0 4px !important;
}
.nav-pills > li:last-child > a {
	border-radius: 0 4px 4px 0 !important;
}
.nav-link.disabled {
	color: #6c757d !important;
}
.nav-pills > li > a {
	margin-left: -1px;
	border-radius: 0 !important;
	border: 1px solid #1d62f0;
	color: #1d62f0;
}
.nav-pills > li > a.active {
	background: #1d62f0;
}
.nav-pills > li > a:hover {
	background: #fafafa;
}
.nav-pills.nav-default > li > a {
	border: 1px solid #607d8b;
	color: #607d8b;
}
.nav-pills.nav-default > li > a.active {
	background: #607d8b;
}
.nav-pills.nav-primary > li > a {
	border: 1px solid #1d62f0;
	color: #1d62f0;
}
.nav-pills.nav-primary > li > a.active {
	background: #1d62f0;
}
.nav-pills.nav-info > li > a {
	border: 1px solid #23ccef;
	color: #23ccef;
}
.nav-pills.nav-info > li > a.active {
	background: #23ccef;
}
.nav-pills.nav-success > li > a {
	border: 1px solid #59d05d;
	color: #59d05d;
}
.nav-pills.nav-success > li > a.active {
	background: #59d05d;
}
.nav-pills.nav-warning > li > a {
	border: 1px solid #fbad4c;
	color: #fbad4c;
}
.nav-pills.nav-warning > li > a.active {
	background: #fbad4c;
}
.nav-pills.nav-danger > li > a {
	border: 1px solid #ff646d;
	color: #ff646d;
}
.nav-pills.nav-danger > li > a.active {
	background: #ff646d;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span,
.pagination > li > a,
.pagination > li > span {
	border-radius: 100px !important;
	margin: 0 2px;
	color: #777;
	border-color: #ddd;
}
.pagination.pg-primary > li.active:first-child > a,
.pagination.pg-primary > li.active:first-child > span,
.pagination.pg-primary > li.active:last-child > a,
.pagination.pg-primary > li.active:last-child > span,
.pagination.pg-primary > li.active > a,
.pagination.pg-primary > li.active > span {
	background: #1d62f0;
	border-color: #1d62f0;
	color: #fff;
}
.pagination.pg-info > li.active:first-child > a,
.pagination.pg-info > li.active:first-child > span,
.pagination.pg-info > li.active:last-child > a,
.pagination.pg-info > li.active:last-child > span,
.pagination.pg-info > li.active > a,
.pagination.pg-info > li.active > span {
	background: #23ccef;
	border-color: #23ccef;
	color: #fff;
}
.pagination.pg-success > li.active:first-child > a,
.pagination.pg-success > li.active:first-child > span,
.pagination.pg-success > li.active:last-child > a,
.pagination.pg-success > li.active:last-child > span,
.pagination.pg-success > li.active > a,
.pagination.pg-success > li.active > span {
	background: #59d05d;
	border-color: #59d05d;
	color: #fff;
}
.pagination.pg-warning > li.active:first-child > a,
.pagination.pg-warning > li.active:first-child > span,
.pagination.pg-warning > li.active:last-child > a,
.pagination.pg-warning > li.active:last-child > span,
.pagination.pg-warning > li.active > a,
.pagination.pg-warning > li.active > span {
	background: #fbad4c;
	border-color: #fbad4c;
	color: #fff;
}
.pagination.pg-danger > li.active:first-child > a,
.pagination.pg-danger > li.active:first-child > span,
.pagination.pg-danger > li.active:last-child > a,
.pagination.pg-danger > li.active:last-child > span,
.pagination.pg-danger > li.active > a,
.pagination.pg-danger > li.active > span {
	background: #ff646d;
	border-color: #ff646d;
	color: #fff;
}
.pagination.pg-default > li.active:first-child > a,
.pagination.pg-default > li.active:first-child > span,
.pagination.pg-default > li.active:last-child > a,
.pagination.pg-default > li.active:last-child > span,
.pagination.pg-default > li.active > a,
.pagination.pg-default > li.active > span {
	background: #607d8b;
	border-color: #607d8b;
	color: #fff;
}
.slider-default .ui-slider-range {
	background: #607d8b;
}
.slider-primary .ui-slider-range {
	background: #1d62f0;
}
.slider-info .ui-slider-range {
	background: #23ccef;
}
.slider-success .ui-slider-range {
	background: #59d05d;
}
.slider-warning .ui-slider-range {
	background: #fbad4c;
}
.slider-danger .ui-slider-range {
	background: #ff646d;
}
.modal .bg-danger .modal-title,
.modal .bg-default .modal-title,
.modal .bg-info .modal-title,
.modal .bg-primary .modal-title,
.modal .bg-success .modal-title,
.modal .bg-warning .modal-title {
	color: #fff !important;
}
.modal-content {
	border-radius: 0.4rem !important;
	border: 0 !important;
}
.mapael .map {
	position: relative;
}
.mapael .zoomButton {
	background-color: #292929;
	border: 1px solid #292929;
	color: #fff;
	width: 15px;
	height: 15px;
	line-height: 12px;
	text-align: center;
	border-radius: 3px;
	cursor: pointer;
	position: absolute;
	top: 0;
	font-weight: 700;
	left: 10px;
	-khtml-user-select: none;
	-o-user-select: none;
	user-select: none;
}
.mapael .zoomReset {
	top: 10px;
}
.mapael .zoomIn {
	top: 30px;
}
.mapael .zoomOut {
	top: 50px;
}
.mapael .mapTooltip {
	position: absolute;
	background-color: #474c4b;
	moz-opacity: 0.7;
	opacity: 0.7;
	filter: alpha(opacity=70);
	border-radius: 10px;
	padding: 10px;
	z-index: 1000;
	max-width: 200px;
	display: none;
	color: #fff;
}
@media screen and (max-width: 700px) {
	.row-card-no-pd [class*='col-'] .card:after {
		width: 0 !important;
	}
	.row-card-no-pd .card {
		border-bottom-width: 1px;
	}
	.row-card-no-pd .card:last-child {
		border-bottom-width: 0;
	}
}
@media screen and (min-width: 992px) {
	.main-header .logo-header {
		line-height: 52px;
	}
	.sidebar .scroll-element {
		opacity: 0;
		transition: all 0.2s;
	}
	.sidebar:hover .scroll-element {
		opacity: 1;
	}
}
@media screen and (max-width: 991px) {
	.main-header,
	.main-panel,
	.navbar-header,
	.sidebar {
		transition: all 0.5s;
	}
	.sidebar {
		position: fixed;
		left: 0 !important;
		right: 0;
		z-index: 10002;
		-webkit-transform: translate3d(-270px, 0, 0);
		-moz-transform: translate3d(-270px, 0, 0);
		-o-transform: translate3d(-270px, 0, 0);
		-ms-transform: translate3d(-270px, 0, 0);
		transform: translate3d(-270px, 0, 0) !important;
	}
	.nav_open .sidebar {
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-o-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0) !important;
	}
	.sidebar .sidebar-wrapper {
		padding-top: 0;
	}
	.nav_open {
		overflow: hidden !important;
	}
	.nav_open .main-header,
	.nav_open .main-panel {
		-webkit-transform: translate3d(260px, 0, 0);
		-moz-transform: translate3d(260px, 0, 0);
		-o-transform: translate3d(260px, 0, 0);
		-ms-transform: translate3d(260px, 0, 0);
		transform: translate3d(260px, 0, 0) !important;
	}
	.main-panel {
		width: 100%;
	}
	.main-panel .content {
		padding: 30px 10px;
	}
	.main-header .logo-header {
		display: flex;
		width: 100% !important;
		text-align: left;
		position: relative;
		justify-content: center;
	}
	.main-header .logo-header .navbar-toggler {
		position: absolute;
		top: auto;
		height: 100%;
		left: 15px;
		opacity: 1;
	}
	.main-header .logo-header .more {
		opacity: 1;
		position: absolute;
		right: 15px;
		color: #545454;
		cursor: pointer;
	}
	.nav-search {
		width: 100%;
		margin-right: 0 !important;
		margin-top: 12px;
	}
	.navbar-header {
		border-top: 1px solid #eee;
		position: absolute;
		width: 100%;
		background: #fff;
		transform: translate3d(0, -200px, 0) !important;
	}
	.topbar_open .navbar-header {
		transform: translate3d(0, 54px, 0) !important;
		border-bottom: 1px solid #eee;
	}
	.topbar_open .main-panel {
		transform: translate3d(0, 125px, 0) !important;
	}
	.nav_open.topbar_open .main-panel {
		transform: translate3d(260px, 125px, 0) !important;
	}
	.navbar-header .navbar-nav {
		width: 100%;
		flex-direction: row;
		justify-content: center;
		margin-left: 0 !important;
		margin-top: 10px;
		margin-bottom: 10px;
		position: relative;
	}
	.navbar-header .navbar-nav .dropdown {
		position: unset;
	}
	.navbar-header .navbar-nav .dropdown-menu {
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		max-width: 280px;
	}
	.profile-pic span {
		display: none;
	}
}
